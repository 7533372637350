import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { LoggedInUserTileComponent } from './components/logged-in-user-tile/logged-in-user-tile.component';
import { UserRoutingModule } from './user-routing.module';
import { ProfileView } from './views/profile/profile.view';
import { SettingsView } from './views/settings/settings.view';

@NgModule({
	imports: [CommonModule, FontAwesomeModule, UserRoutingModule, SharedModule, FormsModule],
	declarations: [ProfileView, LoggedInUserTileComponent, SettingsView],
	exports: [LoggedInUserTileComponent],
})
// tslint:disable-next-line: no-unnecessary-class
export class UserModule {}

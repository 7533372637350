import { Component, HostBinding, Input, Renderer2 } from '@angular/core';
import {
	BlockInfoDto,
	Workspace as BlockIconButtonComponentWorkspace,
} from '../../../api/models/block-info-dto.model';

@Component({
	selector: 'app-block-icon-button',
	templateUrl: './block-icon-button.component.html',
	styleUrls: ['./block-icon-button.component.scss'],
})
export class BlockIconButtonComponent {
	@Input()
	public blockInfo!: BlockInfoDto;

	@Input()
	@HostBinding('class.block-is-completed')
	public blockIsCompleted = false;

	@Input()
	@HostBinding('class.block-is-active')
	public blockIsActive = false;

	@HostBinding('class.classroom')
	public get isClassroomWorkspace() {
		return this.blockInfo.workspace === BlockIconButtonComponentWorkspace.CLASSROOM;
	}

	@HostBinding('class.workshop')
	public get isWorkshopWorkspace() {
		return this.blockInfo.workspace === BlockIconButtonComponentWorkspace.WORKSHOP;
	}

	@HostBinding('class.project')
	public get isProjectWorkspace() {
		return this.blockInfo.workspace === BlockIconButtonComponentWorkspace.PROJECT;
	}

	constructor(private renderer: Renderer2) {}

	public styleSVG(svg: SVGElement) {
		this.renderer.setStyle(svg, 'max-width', '90%');
		this.renderer.setStyle(svg, 'max-height', '90%');
		this.renderer.setStyle(svg, 'fill', 'currentColor !important');
	}
}

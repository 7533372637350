import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { faStar as fasStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { Workspace } from '../../../api/models/block-info-dto.model';
import { PersonalBlockSummaryDto } from '../../../api/models/personal-block-summary-dto.model';
import { PersonalChapterDto } from '../../../api/models/personal-chapter-dto.model';
import { PersonalSectionDto } from '../../../api/models/personal-section-dto.model';

@Component({
	selector: 'app-course-library-chapter-row',
	templateUrl: './course-library-chapter-row.component.html',
	styleUrls: ['./course-library-chapter-row.component.scss'],
})
export class CourseLibraryChapterRowComponent {
	@Input()
	public set personalChapter(chapter: PersonalChapterDto) {
		const isBlockCompleted = (b: PersonalBlockSummaryDto) => b.state.isCompleted;
		const getBlocks = (s: PersonalSectionDto) => s.blocks;
		const isClassroomBlock = (b: PersonalBlockSummaryDto) =>
			b.info.workspace === Workspace.CLASSROOM;
		const isWorkshopBlock = (b: PersonalBlockSummaryDto) =>
			b.info.workspace === Workspace.WORKSHOP;
		const isProjectBlock = (b: PersonalBlockSummaryDto) =>
			b.info.workspace === Workspace.PROJECT;

		if (!chapter) return;

		this.chapter = chapter;

		const completedBlocks = chapter.sections.flatMap(getBlocks).filter(isBlockCompleted);
		this.classroomBlocks = completedBlocks.filter(isClassroomBlock);
		this.workshopBlocks = completedBlocks.filter(isWorkshopBlock);
		this.projectBlocks = completedBlocks.filter(isProjectBlock);
	}

	@HostBinding('style.display')
	public get cssDisplay() {
		if (
			this.classroomBlocks.length === 0 &&
			this.workshopBlocks.length === 0 &&
			this.projectBlocks.length === 0 &&
			!this.isPlaceholder
		) {
			return 'none';
		}
		return 'block';
	}

	@HostBinding('class.placeholder')
	public get isPlaceholder() {
		return !this.chapter;
	}

	public chapter?: PersonalChapterDto;
	public classroomBlocks: Array<PersonalBlockSummaryDto> = [];
	public workshopBlocks: Array<PersonalBlockSummaryDto> = [];
	public projectBlocks: Array<PersonalBlockSummaryDto> = [];

	public fasStar = fasStar;

	constructor(public router: Router) {}
}

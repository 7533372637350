import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import Player from '@vimeo/player';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { PersonalVimeoBlockContentDto } from '../../../api/models/personal-vimeo-block-content-dto.model';
import { LocalisedString } from '../../../api/utils/localised-string';
import { CurrentContentLocaleService } from '../../../shared/injectables/current-content-locale.service';
import {
	BlockComponent,
	BlockContainerSettings,
} from '../block-container/block-container.component';

@Component({
	selector: 'app-vimeo-block',
	templateUrl: './vimeo-block.component.html',
	styleUrls: ['./vimeo-block.component.scss'],
})
export class VimeoBlockComponent implements BlockComponent, OnInit, OnChanges, OnDestroy {
	@Input()
	public blockContent!: PersonalVimeoBlockContentDto;
	@Output()
	public blockCompleted = new EventEmitter<void>();
	@Output()
	public blockInteraction = new EventEmitter<void>();
	@Output()
	public startNextBlockCountdown = new EventEmitter<void>();
	@Output()
	public stopNextBlockCountdown = new EventEmitter<void>();
	@Output()
	public changeContainerSettings = new EventEmitter<BlockContainerSettings>();

	public vimeoPlayer?: Player;

	@ViewChild('vimeoPlayerPlaceholder')
	private vimeoPlayerPlaceholder!: ElementRef<HTMLDivElement>;
	@ViewChild('vimeoPlayerWrapper')
	private vimeoPlayerWrapper!: ElementRef<HTMLDivElement>;

	private vimeoId: Observable<string>;
	private vimeoIdUnlocalised = new BehaviorSubject(LocalisedString.create({}));

	private blockChanged = false;

	constructor(
		private currentContentLocaleService: CurrentContentLocaleService,
		private renderer: Renderer2,
	) {
		this.vimeoId = combineLatest([
			this.currentContentLocaleService.currentLocale$.pipe(distinctUntilChanged()),
			this.vimeoIdUnlocalised.pipe(
				distinctUntilChanged((s1, s2) => s1.equals(s2)),
				filter(unlId => unlId.values !== {}),
				tap(() => (this.blockChanged = true)),
			),
		]).pipe(
			map(([locale, unlId]) => unlId.translation(locale)),
			filter(s => !!s),
			shareReplay(),
		);
		this.vimeoId.subscribe(async vimeoId => {
			const currentTime = this.vimeoPlayer ? await this.vimeoPlayer.getCurrentTime() : 0;
			await this.terminateVimeoPlayer();
			await this.initializeVimeoPlayer(vimeoId);
			if (!this.blockChanged) {
				this.vimeoPlayer!.setCurrentTime(currentTime);
			} else this.blockChanged = false;
		});
	}

	public ngOnInit() {
		this.changeContainerSettings.emit({
			showPreviousBlockControl: true,
			showNextBlockControl: true,
			showFullscreenControl: true,
		});
	}

	public async ngOnChanges(changes: SimpleChanges) {
		if (changes.blockContent) {
			const currentVimeoId = changes.blockContent.currentValue.vimeoId;
			this.vimeoIdUnlocalised.next(LocalisedString.fromDto(currentVimeoId));
		}
	}

	public async ngOnDestroy() {
		if (this.vimeoPlayer) await this.vimeoPlayer!.destroy();
	}

	private async initializeVimeoPlayer(vimeoId: string) {
		if (this.vimeoPlayer) return;

		// initialize player object into local variable
		const playerOptions = { id: Number(vimeoId), autoplay: true };
		const newVimeoPlayer = new Player(this.vimeoPlayerWrapper.nativeElement, playerOptions);

		// cleanup player object if an error happens during loading
		try {
			await newVimeoPlayer.ready();
		} catch (err) {
			await newVimeoPlayer.destroy();
			throw err;
		}

		// set styling of iframe player
		const playerElement = this.renderer.selectRootElement('.vimeo-player-wrapper > iframe');
		this.renderer.setStyle(playerElement, 'position', 'absolute');
		this.renderer.setStyle(playerElement, 'top', '0');
		this.renderer.setStyle(playerElement, 'left', '0');
		this.renderer.setStyle(playerElement, 'width', '100%');
		this.renderer.setStyle(playerElement, 'height', '100%');

		// update container ratio and register event handlers
		this.updateContainerRatioToVideo(newVimeoPlayer);
		this.registerPlayerEventHandlers(newVimeoPlayer);

		// set player object to global variable to update view
		this.vimeoPlayer = newVimeoPlayer;
	}

	private async updateContainerRatioToVideo(vimeoPlayer: Player) {
		// update ratio of vimeoPlayerWrapper and the vimeoPlayerPlaceholder to ratio of video
		const [videoWidth, videoHeight] = await Promise.all([
			vimeoPlayer!.getVideoWidth(),
			vimeoPlayer!.getVideoHeight(),
		]);
		const ratio = (videoHeight / videoWidth) * 100 + '%';
		this.renderer.setStyle(this.vimeoPlayerWrapper.nativeElement, 'padding-bottom', ratio);
		this.renderer.setStyle(this.vimeoPlayerPlaceholder.nativeElement, 'padding-bottom', ratio);
	}

	private async registerPlayerEventHandlers(vimeoPlayer: Player) {
		// emit completed event when video playback reaches 95% or more
		const completeCallback = (event: { percent: number }) => {
			if (event.percent < 0.95) return;
			this.blockCompleted.emit();
			vimeoPlayer!.off('timeupdate', completeCallback);
		};
		vimeoPlayer!.on('timeupdate', completeCallback);

		// emit countdown-start event when video playback reaches completes
		vimeoPlayer!.on('ended', () => {
			this.startNextBlockCountdown.emit();

			// emit countdown-stop event if user restarts/seeks video
			const stopCountdownCallback = () => {
				this.stopNextBlockCountdown.emit();
				vimeoPlayer!.off('timeupdate', stopCountdownCallback);
			};
			vimeoPlayer!.on('timeupdate', stopCountdownCallback);
		});

		// emit interaction event when video is played or paused
		const interactionCallback = () => this.blockInteraction.emit();
		vimeoPlayer!.on('play', interactionCallback);
		vimeoPlayer!.on('pause', interactionCallback);
	}

	private async terminateVimeoPlayer() {
		if (!this.vimeoPlayer) return;

		const oldVimeoPlayer = this.vimeoPlayer;
		this.vimeoPlayer = undefined;
		await oldVimeoPlayer.destroy();
	}
}


export enum Permission {
	MANAGE_USERS = 'MANAGE_USERS',
	MANAGE_BITMASTERS = 'MANAGE_BITMASTERS',
	MANAGE_CONTENT_BLOCKS = 'MANAGE_CONTENT_BLOCKS',
	MANAGE_COURSES = 'MANAGE_COURSES',
	MANAGE_ICONS = 'MANAGE_ICONS',
}

export interface GlobalPermissionDto {
	id: string;
	permission: Permission;
}

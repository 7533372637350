import { LocalisedStringDto } from './../models/localised-string-dto.model';

export enum Type {
	VIMEO = 'VIMEO',
	MARKDOWN = 'MARKDOWN',
	STORYLINE = 'STORYLINE',
	UNITY = 'UNITY',
}
export enum Workspace {
	CLASSROOM = 'CLASSROOM',
	WORKSHOP = 'WORKSHOP',
	PROJECT = 'PROJECT',
}

export interface BlockInfoDto {
	blockId: string;
	blockAssignmentId: string;
	blockAssignmentKey: string;
	name: LocalisedStringDto[];
	type: Type;
	workspace: Workspace;
	iconUrl: string;
}

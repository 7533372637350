import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Optional,
	Output,
	SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCompressAlt } from '@fortawesome/pro-regular-svg-icons/faCompressAlt';
import { faExpandAlt } from '@fortawesome/pro-regular-svg-icons/faExpandAlt';
import { faFrown } from '@fortawesome/pro-regular-svg-icons/faFrown';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { Observable, of, timer } from 'rxjs';
import { filter, map, takeWhile, withLatestFrom } from 'rxjs/operators';
import { Type as BlockType } from '../../../api/models/block-info-dto.model';
import { PersonalBlockSummaryDto } from '../../../api/models/personal-block-summary-dto.model';
import {
	BlockContent,
	isSameBlockContent,
} from '../../../api/typeguards/block-content.type-guards';
import { CurrentPlaylistService } from '../../services/current-playlist.service';

export interface BlockContainerSettings {
	showPreviousBlockControl: boolean;
	showNextBlockControl: boolean;
	showFullscreenControl: boolean;
}

export interface BlockComponent {
	blockCompleted: EventEmitter<void>;
	blockInteraction: EventEmitter<void>;
	startNextBlockCountdown: EventEmitter<void>;
	stopNextBlockCountdown: EventEmitter<void>;
	changeContainerSettings: EventEmitter<BlockContainerSettings>;
}

@Component({
	selector: 'app-block-container',
	templateUrl: './block-container.component.html',
	styleUrls: ['./block-container.component.scss'],
})
export class BlockContainerComponent implements OnChanges {
	@Input()
	public blockContent!: BlockContent;
	@Output()
	public blockCompleted = new EventEmitter<void>();
	@Output()
	public blockInteraction = new EventEmitter<void>();

	public previousBlock$: Observable<PersonalBlockSummaryDto | undefined>;
	public nextBlock$: Observable<PersonalBlockSummaryDto | undefined>;
	public canGoToNextBlock$: Observable<boolean>;
	public canGoToPreviousBlock$: Observable<boolean>;

	public BlockType = BlockType;
	public nextBlockCountdown$?: Observable<number> = undefined;
	public containerSettings: BlockContainerSettings = {
		showPreviousBlockControl: false,
		showNextBlockControl: false,
		showFullscreenControl: false,
	};

	public faChevronLeft = faChevronLeft;
	public faChevronRight = faChevronRight;
	public faFrown = faFrown;
	public faExpandAlt = faExpandAlt;
	public faCompressAlt = faCompressAlt;

	constructor(
		private hostElement: ElementRef,
		private router: Router,
		private route: ActivatedRoute,
		@Optional() private currentPlaylistService?: CurrentPlaylistService,
	) {
		if (this.currentPlaylistService) {
			this.previousBlock$ = this.currentPlaylistService.previousBlockSummary$;
			this.nextBlock$ = this.currentPlaylistService.nextBlockSummary$;
			this.canGoToNextBlock$ = this.currentPlaylistService.canGoToNextBlock$;
			this.canGoToPreviousBlock$ = this.currentPlaylistService.canGoToPreviousBlock$;
		} else {
			this.previousBlock$ = of(undefined);
			this.nextBlock$ = of(undefined);
			this.canGoToNextBlock$ = of(false);
			this.canGoToPreviousBlock$ = of(false);
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (
			changes.blockContent &&
			!isSameBlockContent(
				changes.blockContent.currentValue,
				changes.blockContent.previousValue,
			)
		) {
			this.onStopNextBlockCountdownEvent();
		}
	}

	public onChildComponentBlockCompleted() {
		this.blockCompleted.emit();
	}

	public onChildComponentBlockInteraction() {
		this.blockInteraction.emit();
	}

	public onStartNextBlockCountdownEvent() {
		const countdownDurationInSeconds = 5;
		this.nextBlockCountdown$ = timer(0, 1000).pipe(
			map(tickCount => countdownDurationInSeconds - tickCount),
			takeWhile(remainingSeconds => remainingSeconds >= 0),
		);
		const sub = this.nextBlockCountdown$
			.pipe(
				filter(time => time === 0),
				withLatestFrom(this.nextBlock$),
				map(x => x[1]),
			)
			.subscribe(nextBlock => {
				sub.unsubscribe();
				this.nextBlockCountdown$ = undefined;
				this.router.navigate(['..', nextBlock!.info.blockAssignmentKey], {
					relativeTo: this.route,
				});
			});
	}

	public onStopNextBlockCountdownEvent() {
		// removing the observable reference causes the async pipe to cancel the subscription
		// after this, the timer wont have any subscribers and therefore will be stopped
		this.nextBlockCountdown$ = undefined;
	}

	public onChangeContainerSettingsEvent(settings: BlockContainerSettings) {
		// use setTimeout to finish rendering before changing component properties
		setTimeout(() => (this.containerSettings = settings));
	}

	public onNavigateToNextBlock() {
		if (this.currentPlaylistService) this.currentPlaylistService.navigateToNextBlock();
	}

	public onNavigateToPreviousBlock() {
		if (this.currentPlaylistService) this.currentPlaylistService.navigateToPreviousBlock();
	}

	public enableFullscreenComponent() {
		const hostElement = this.hostElement.nativeElement as any;

		if (hostElement.requestFullscreen) hostElement.requestFullscreen();
		else if (hostElement.mozRequestFullScreen) hostElement.mozRequestFullScreen();
		else if (hostElement.msRequestFullscreen) hostElement.msRequestFullscreen();
		else if (hostElement.webkitRequestFullscreen) hostElement.webkitRequestFullscreen();
	}

	public disableFullscreenComponent() {
		const documentElement = document as any;

		if (documentElement.exitFullscreen) documentElement.exitFullscreen();
		else if (documentElement.mozCancelFullScreen) documentElement.mozCancelFullScreen();
		else if (documentElement.msExitFullscreen) documentElement.msExitFullscreen();
		else if (documentElement.webkitExitFullscreen) documentElement.webkitExitFullscreen();
	}
}

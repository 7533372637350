import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { LocalisedString } from '../../api/utils/localised-string';
import { CurrentContentLocaleService } from '../injectables/current-content-locale.service';

@Pipe({
	name: 'localise',
	pure: false,
})
export class LocalisePipe implements PipeTransform {
	private asyncPipe: AsyncPipe;

	constructor(
		private currentContentLocaleService: CurrentContentLocaleService,
		_ref: ChangeDetectorRef,
	) {
		this.asyncPipe = new AsyncPipe(_ref);
	}

	public transform(localisedStringDto: LocalisedString.Dto) {
		return this.asyncPipe.transform(
			this.currentContentLocaleService.translate(LocalisedString.fromDto(localisedStringDto)),
		);
	}
}

import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons/faShieldCheck';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { UsersApiService } from '../../../api/services/users.service';
import { LoadingStatus } from '../../../shared/util/loading-status';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './reset-password.view.html',
	styleUrls: ['./reset-password.view.scss'],
})
export class ResetPasswordView {
	public newPassword = '';
	public message?: { icon?: IconDefinition; text: string } = undefined;
	public submitLoadingStatus = new LoadingStatus();

	@ViewChild('changePasswordForm')
	private changePasswordForm!: NgForm;

	constructor(private userApiService: UsersApiService, private route: ActivatedRoute) {}

	public async resetPasswordFormSubmit() {
		const loadingId = this.submitLoadingStatus.startLoadingProcess();

		try {
			await this.userApiService.changePassword({
				passwordResetToken: this.route.snapshot.queryParamMap.get('reset-token')!,
				newPassword: this.newPassword,
			});
			this.message = {
				icon: faShieldCheck,
				text: 'Your password has been changed!',
			};
		} catch (err) {
			this.message = {
				icon: faExclamationCircle,
				text: 'Sorry, something went wrong. Has the link maybe expired?',
			};
			this.changePasswordForm.form.markAsPristine();
		} finally {
			this.submitLoadingStatus.stopLoadingProcess(loadingId);
		}
	}
}

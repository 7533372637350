import { BaseApiService } from './base-api.service';
import { SessionCredentialsDto } from './../models/session-credentials-dto.model';
import { SessionInformationDto } from './../models/session-information-dto.model';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SessionApiService extends BaseApiService {
	private constructor(private http: HttpClient) {
		super();
	}

	/** Get current session */
	public getCurrentSession$(): Observable<SessionInformationDto> {
		const path = `session`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<SessionInformationDto>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getCurrentSession(): Promise<SessionInformationDto> {
		return this.getCurrentSession$().toPromise();
	}

	/** Start new session with given username and password */
	public createSession$(body: SessionCredentialsDto): Observable<SessionInformationDto> {
		const path = `session`;

		const options: any = { headers: this.defaultHeaders };
		options.body = body;

		return this.http.request<SessionInformationDto>(
			'POST',
			this.basePath + path,
			options as object,
		);
	}

	public createSession(body: SessionCredentialsDto): Promise<SessionInformationDto> {
		return this.createSession$(body).toPromise();
	}

	/** Terminate current session */
	public endSession$(): Observable<void> {
		const path = `session`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<void>(
			'DELETE',
			this.basePath + path,
			options as object,
		);
	}

	public endSession(): Promise<void> {
		return this.endSession$().toPromise();
	}

}

import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons/faPlayCircle';
import { faRocket } from '@fortawesome/pro-regular-svg-icons/faRocket';
import { faBooks } from '@fortawesome/pro-solid-svg-icons/faBooks';
import { faTrophy } from '@fortawesome/pro-solid-svg-icons/faTrophy';
import { faTv } from '@fortawesome/pro-solid-svg-icons/faTv';
import { faUserAstronaut } from '@fortawesome/pro-solid-svg-icons/faUserAstronaut';
import { merge, Observable, of as observableFromValue, throwError } from 'rxjs';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { InviteSummaryDto } from '../../../api/models/invite-summary-dto.model';
import { CoursesApiService } from '../../../api/services/courses.service';
import { UsersApiService } from '../../../api/services/users.service';
import {
	BlockContent,
	isCategoryGame,
	isCategoryVideo,
} from '../../../api/typeguards/block-content.type-guards';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { CurrentContentLocaleService } from '../../../shared/injectables/current-content-locale.service';
import { NavigationService } from '../../../shared/injectables/navigation-service';

@Component({
	selector: 'app-enroll',
	templateUrl: './enroll.view.html',
	styleUrls: ['./enroll.view.scss'],
	providers: [CurrentContentLocaleService],
})
export class EnrollView {
	public queryUid$: Observable<string>;
	public courseKey$: Observable<string>;
	public course$: Observable<InviteSummaryDto>;
	public backgroundImageUrl$: Observable<string>;
	public videoCount$: Observable<number>;
	public gameCount$: Observable<number>;

	public hasCompletedFirstBlock = false;
	public state: 'start' | 'authenticated' | 'firstBlock' | 'register';
	public registerModel = {
		firstName: '',
		lastName: '',
		password1: '',
		password2: '',
		tos: false,
	};

	public faBooks = faBooks;
	public faTv = faTv;
	public faTrophy = faTrophy;
	public faUserAstronaut = faUserAstronaut;
	public faPlayCircle = faPlayCircle;
	public faRocket = faRocket;

	@ViewChild('backgroundImage')
	set backgroundImage(img: ElementRef<HTMLImageElement>) {
		img.nativeElement.addEventListener('load', () => {
			img.nativeElement.style.opacity = '1';
		});
	}

	constructor(
		private activatedRoute: ActivatedRoute,
		private coursesApiService: CoursesApiService,
		private userApiService: UsersApiService,
		private authenticationService: AuthenticationService,
		private navigationService: NavigationService,
		private currentContentLocaleService: CurrentContentLocaleService,
	) {
		this.state = this.authenticationService.isAuthenticated ? 'authenticated' : 'start';

		this.queryUid$ = this.activatedRoute.queryParams.pipe(
			map(query => query.uid as string),
			shareReplay(),
		);
		this.courseKey$ = this.activatedRoute.params.pipe(
			map(params => params.courseKey as string),
			shareReplay(),
		);

		this.course$ = combineLatest([this.courseKey$, this.queryUid$]).pipe(
			switchMap(([courseKey, uid]) =>
				this.coursesApiService.getInviteSummary$(courseKey, uid).pipe(
					catchError((err, caught) => {
						this.authenticationService
							.updateSessionInfo()
							.then(() => this.navigationService.navigateToCourseRoadmap(courseKey))
							.catch(() => this.navigationService.navigateToLogin());
						return throwError(caught);
					}),
				),
			),
			tap(course =>
				this.currentContentLocaleService.setAvailableLocales(course.availableLocales),
			),
			shareReplay(),
		);

		this.backgroundImageUrl$ = merge(
			observableFromValue(''),
			this.course$.pipe(
				map(s => s.imageUrl),
				shareReplay(),
			),
		);

		const sumOfCategory = (blockCmp: (block: BlockContent) => boolean) => {
			return (c: InviteSummaryDto) => {
				return c.blockTypeCounts
					.filter(b => blockCmp({ type: b.type }))
					.map(b => b.count)
					.reduce((a, b) => a + b, 0);
			};
		};

		this.videoCount$ = this.course$.pipe(map(sumOfCategory(isCategoryVideo), shareReplay()));
		this.gameCount$ = this.course$.pipe(map(sumOfCategory(isCategoryGame), shareReplay()));
	}

	public watchFirstVideo() {
		this.state = 'firstBlock';
	}

	public register() {
		this.state = 'register';
	}

	public get formIsValid() {
		return (
			this.registerModel.firstName.length > 0 &&
			this.registerModel.lastName.length > 0 &&
			this.registerModel.password1.length >= 10 &&
			this.registerModel.password1 === this.registerModel.password2 &&
			this.registerModel.tos
		);
	}

	public get passwordsDontMatch() {
		return (
			this.registerModel.password2.length > 0 &&
			this.registerModel.password1 !== this.registerModel.password2
		);
	}

	public async sendRegistration() {
		const subscription = combineLatest([this.queryUid$, this.course$]).subscribe(
			async ([uid, course]) => {
				const user = await this.userApiService.registerUser({
					userId: uid,
					firstName: this.registerModel.firstName,
					lastName: this.registerModel.lastName,
					password: this.registerModel.password1,
				});

				await this.authenticationService.login(
					user.mailAddress,
					this.registerModel.password1,
				);

				if (this.hasCompletedFirstBlock) {
					this.coursesApiService.updateUserBlockState(
						course.courseKey,
						course.firstBlockInfo.blockAssignmentKey,
						{ isFavourite: true },
					);
				}

				this.navigationService.navigateToCourseRoadmap(course.courseKey);
				subscription.unsubscribe();
			},
		);
	}

	public async authenticatedEnroll() {
		const subscription = this.course$.subscribe(async course => {
			await this.authenticationService.updateSessionInfo();
			this.navigationService.navigateToCourseRoadmap(course.courseKey);
			subscription.unsubscribe();
		});
	}
}

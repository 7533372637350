import { ErrorHandler, Injectable, NgZone } from '@angular/core';

@Injectable()
export class UncaughtErrorHandler implements ErrorHandler {
	constructor(private ngZone: NgZone) {}

	public handleError(error: any) {
		// use ngZone to trigger change-detection after code execution
		// https://stackoverflow.com/questions/44975477/angular2-ng-toasty-errorhandler
		this.ngZone.run(() => {
			console.error(error);
		});
	}
}

import { PersonalUnityBlockContentDto } from '../models/personal-unity-block-content-dto.model';
import { LocalisedString } from '../utils/localised-string';
import { PersonalMarkdownBlockContentDto } from './../models/personal-markdown-block-content-dto.model';
import { PersonalStorylineBlockContentDto } from './../models/personal-storyline-block-content-dto.model';
import {
	Type,
	PersonalVimeoBlockContentDto,
} from './../models/personal-vimeo-block-content-dto.model';

export type BlockContent =
	| PersonalVimeoBlockContentDto
	| PersonalStorylineBlockContentDto
	| PersonalMarkdownBlockContentDto
	| { type: string };

export const isVimeoBlockContent = (
	blockContent: BlockContent,
): blockContent is PersonalVimeoBlockContentDto => {
	return blockContent.type === Type.VIMEO;
};

export const isStorylineBlockContent = (
	blockContent: BlockContent,
): blockContent is PersonalStorylineBlockContentDto => {
	return blockContent.type === Type.STORYLINE;
};

export const isMarkdownBlockContent = (
	blockContent: BlockContent,
): blockContent is PersonalMarkdownBlockContentDto => {
	return blockContent.type === Type.MARKDOWN;
};

export const isUnityBlockContent = (
	blockContent: BlockContent,
): blockContent is PersonalUnityBlockContentDto => {
	return blockContent.type === Type.UNITY;
};

export const isSameBlockContent = (b1: BlockContent, b2: BlockContent) => {
	if (!b1 || !b2) return false;
	if (b1 === b2) return true;

	if (isVimeoBlockContent(b1) && isVimeoBlockContent(b2)) {
		return LocalisedString.fromDto(b1.vimeoId).equals(b2.vimeoId);
	}
	if (isStorylineBlockContent(b1) && isStorylineBlockContent(b2)) {
		return LocalisedString.fromDto(b1.storylineGameUrl).equals(b2.storylineGameUrl);
	}
	if (isUnityBlockContent(b1) && isUnityBlockContent(b2)) {
		return b1.unityGameUrl === b2.unityGameUrl;
	}

	return false;
};

export const isCategoryVideo = (blockContent: BlockContent) => {
	return isVimeoBlockContent(blockContent);
};

export const isCategoryGame = (blockContent: BlockContent) => {
	return isStorylineBlockContent(blockContent);
};

<div class="container-fluid root-container">
	<ng-container *ngIf="block$ | async as block; else loadingPlaceholder">
		<header class="d-flex">
			<div class="equal-width">
				<a [routerLink]="navigationService.getCourseRoadmapLink(block.course.courseKey)">
					<app-icon-button [icon]="faChevronLeft">Roadmap</app-icon-button>
				</a>
			</div>
			<div class="equal-width text-center d-md-block d-none">
				<img src="{{ block.course.brandingImageUrl }}" height="14" alt="Company Logo">
				<h1>{{ block.course.courseName | localise }}</h1>
			</div>
			<app-logged-in-user-tile class="equal-width"></app-logged-in-user-tile>
		</header>
		<div class="d-md-none">
			<img src="{{ block.course.brandingImageUrl }}" height="14" alt="Company Logo">
			<h1>{{ block.course.courseName | localise }}</h1>
		</div>
		<main class="galacta-card">
			<div class="chapter-navigation row">
				<div class="col-4 chapter-label">
					<a *ngIf="block.lastBlockOfPreviousChapter"
					   [routerLink]="navigationService.getBlockPlaylistLink(block.course.courseKey, block.lastBlockOfPreviousChapter!.info.blockAssignmentKey)">
						<app-icon-button [icon]="faChevronLeft">Previous Chapter</app-icon-button>
					</a>
				</div>
				<div class="col-4 chapter-label text-center">Chapter {{ block.chapter.ordinal }}
				</div>
				<div class="col-4 chapter-label text-right">
					<ng-container *ngIf="chapterIsCompleted$ | async; else uncompletedWarning">
						<a *ngIf="block.firstBlockOfNextChapter"
						   [routerLink]="currentPlaylistService.getLinkToBlock(block.firstBlockOfNextChapter!.info.blockAssignmentKey) | async">
							<app-icon-button [icon]="faChevronRight"
											 [iconPosition]="IconButtonComponentIconPosition.Right">
								Next Chapter
							</app-icon-button>
						</a>
					</ng-container>
					<ng-template #uncompletedWarning>
						<div class="uncompleted-chapter">Chapter not completed</div>
					</ng-template>
				</div>
			</div>

			<div class="chapter-name row">
				<div class="col-12 text-center">{{ block.chapter.name | localise }}</div>
			</div>

			<div class="block-navigation row fade-x">
				<div class="col-12 text-center" style="overflow-x: scroll;">
					<div class="icon-wrapper">
						<ng-container
							*ngFor="let cBlock of blocksInChapter$ | async; last as isLastBlock">
							<a [routerLink]="currentPlaylistService.getLinkToBlock(cBlock.info.blockAssignmentKey) | async">
								<app-block-icon-button [blockInfo]="cBlock.info"
													   [blockIsCompleted]="cBlock.state.isCompleted"
													   [blockIsActive]="block.info.blockAssignmentKey === cBlock.info.blockAssignmentKey">
								</app-block-icon-button>
							</a>
							<div *ngIf="!isLastBlock"
								 class="block-separator"></div>
						</ng-container>
					</div>
				</div>
			</div>

			<div class="block-content row">
				<div class="col-12">
					<div class="block-component-wrapper">
						<app-block-container [blockContent]="block.content"
											 (blockCompleted)="onBlockCompleted()"
											 (blockInteraction)="onBlockInteraction()"></app-block-container>
					</div>
				</div>
			</div>

			<div class="block-info row">
				<div class="col-12 block-info-wrapper">
					<div class="block-number">{{ blockNumberInChapter }}</div>
					<div class="block-teaser">
						<div class="block-name">{{ block.info.name | localise }}</div>
						<div class="block-description">
							<!-- TODO: store block-descriptions in database and display them here --></div>
					</div>
					<div class="block-actions text-right">
						<app-icon-button [icon]="block.state.isFavourite ? fasStar : farStar"
										 (click)="toggleBlockFavourited(block.state.isFavourite)"></app-icon-button>
						<app-locale-dropdown></app-locale-dropdown>
					</div>
				</div>
			</div>
		</main>
	</ng-container>

	<router-outlet></router-outlet>
</div>

<ng-template #loadingPlaceholder>
	<div class="loading-placeholder-container" *ngIf="loadingPlaceholderDelayExpired">
		<main class="galacta-card">
			<div class="chapter-navigation row">
				<div class="chapter-label col-12 d-flex flex-column align-items-center">
					<div class="chapter-label-placeholder"></div>
				</div>
			</div>

			<div class="chapter-name row">
				<div class="col-12 d-flex flex-column align-items-center">
					<div class="chapter-name-placeholder"></div>
				</div>
			</div>

			<div class="block-navigation row">
				<div class="col-12 text-center">
					<div class="icon-wrapper">
						<ng-container *ngFor="let index of [1,2,3,4,5,6]; last as isLast">
							<div class="block-circle"></div>
							<div *ngIf="!isLast" class="block-separator"></div>
						</ng-container>
					</div>
				</div>
			</div>

			<div class="block-content row">
				<div class="col-12">
					<div class="block-component-placeholder"></div>
				</div>
			</div>

			<div class="block-info row">
			</div>
		</main>
	</div>
</ng-template>

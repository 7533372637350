import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg';
import { BlockIconButtonComponent } from './components/block-icon-button/block-icon-button.component';
import { CardViewComponent } from './components/card-view/card-view.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { LocaleDropdownComponent } from './components/locale-dropdown/locale-dropdown.component';
import { NotImplementedModalComponent } from './components/not-implemented-modal/not-implemented-modal.component';
import { LocalisePipe } from './pipes/localise.pipe';

@NgModule({
	imports: [
		HttpClientModule,
		InlineSVGModule.forRoot(),
		CommonModule,
		RouterModule,
		FontAwesomeModule,
		NgbModule,
	],
	declarations: [
		NotImplementedModalComponent,
		IconButtonComponent,
		BlockIconButtonComponent,
		LocaleDropdownComponent,
		LocalisePipe,
		CardViewComponent,
	],
	exports: [
		IconButtonComponent,
		BlockIconButtonComponent,
		LocaleDropdownComponent,
		LocalisePipe,
		CardViewComponent,
	],
	entryComponents: [NotImplementedModalComponent],
})
// tslint:disable-next-line: no-unnecessary-class
export class SharedModule {}

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalisedString } from '../../../api/utils/localised-string';
import { CurrentContentLocaleService } from '../../injectables/current-content-locale.service';

const languages = LocalisedString.create({
	en: 'English',
	de: 'Deutsch',
});

const flags = LocalisedString.create({
	en: 'gb',
	en_US: 'us',
	de_AT: 'at',
	de_DE: 'de',
});

interface Locale {
	locale: string;
	flag: string;
	language: string;
}

@Component({
	selector: 'app-locale-dropdown',
	templateUrl: './locale-dropdown.component.html',
	styleUrls: ['./locale-dropdown.component.scss'],
})
export class LocaleDropdownComponent {
	public availableLocales$: Observable<Array<Locale>>;
	public currentLocale$: Observable<Locale>;

	constructor(private currentContentLocaleService: CurrentContentLocaleService) {
		this.availableLocales$ = this.currentContentLocaleService.availableLocales$.pipe(
			map(locales => {
				return locales
					.map(locale => {
						return {
							locale,
							flag: flags.translation(locale),
							language: languages.translation(locale),
						};
					})
					.sort((l1, l2) => l1.language.localeCompare(l2.language));
			}),
		);
		this.currentLocale$ = this.currentContentLocaleService.currentLocale$.pipe(
			map(locale => {
				return {
					locale,
					flag: flags.translation(locale),
					language: languages.translation(locale),
				};
			}),
		);
	}

	public choose(locale: string) {
		this.currentContentLocaleService.setCurrentLocale(locale);
	}
}

import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { PersonalUnityBlockContentDto } from '../../../api/models/personal-unity-block-content-dto.model';
import { isSameBlockContent } from '../../../api/typeguards/block-content.type-guards';
import { BlockContainerSettings } from '../block-container/block-container.component';

@Component({
	selector: 'app-unity-block',
	templateUrl: './unity-block.component.html',
	styleUrls: ['./unity-block.component.scss'],
})
export class UnityBlockComponent implements OnInit, OnChanges, OnDestroy {
	@Input()
	public blockContent!: PersonalUnityBlockContentDto;
	@Output()
	public blockCompleted = new EventEmitter<void>();
	@Output()
	public blockInteraction = new EventEmitter<void>();
	@Output()
	public changeContainerSettings = new EventEmitter<BlockContainerSettings>();
	@Output()
	public navigateToNextBlock = new EventEmitter<void>();
	@Output()
	public navigateToPreviousBlock = new EventEmitter<void>();

	public unityGameUrl = new BehaviorSubject<SafeResourceUrl | undefined>(undefined);
	private iFrameMouseMovedEvents = new Subject<void>();

	private terminateUnityMessageSubscription?: () => void;
	private componentDestroyedNotifier$ = new Subject<boolean>();

	constructor(private sanitizer: DomSanitizer, private renderer: Renderer2) {}

	public ngOnInit(): void {
		this.terminateUnityMessageSubscription = this.renderer.listen('window', 'message', event =>
			this.onUnityMessage(event),
		);

		this.changeContainerSettings.emit({
			showPreviousBlockControl: true,
			showNextBlockControl: true,
			showFullscreenControl: true,
		});

		this.iFrameMouseMovedEvents
			.pipe(throttleTime(1000), takeUntil(this.componentDestroyedNotifier$))
			.subscribe(() => {
				this.blockInteraction.emit();
			});
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (
			changes.blockContent &&
			!isSameBlockContent(
				changes.blockContent.currentValue,
				changes.blockContent.previousValue,
			)
		) {
			this.unityGameUrl.next(
				this.sanitizer.bypassSecurityTrustResourceUrl(
					changes.blockContent.currentValue.unityGameUrl,
				),
			);
		}
	}

	public ngOnDestroy() {
		this.terminateUnityMessageSubscription?.();
		this.componentDestroyedNotifier$.next(true);
		this.componentDestroyedNotifier$.complete();
	}

	private onUnityMessage(messageEvent: MessageEvent) {
		if (
			!messageEvent.origin.match(
				/^https:\/\/galacta-unity-games.ams3.digitaloceanspaces.com/,
			) &&
			!messageEvent.origin.match(/^https:\/\/unity.assets.galacta.com/) &&
			!messageEvent.origin.match(/^https:\/\/app(\..+)?.galacta.com/) &&
			!messageEvent.origin.match(/^https?:\/\/.*\.test/)
		) {
			console.error('message from untrusted origin', messageEvent.origin);
			return;
		}

		switch (messageEvent.data) {
			case 'Unity.InstanceLoaded':
				break;
			case 'Unity.NavigateToNextBlock':
				this.navigateToNextBlock.emit();
				break;
			case 'Unity.NavigateToPreviousBlock':
				this.navigateToPreviousBlock.emit();
				break;
			case 'Unity.BlockCompleted':
				this.blockCompleted.emit();
				break;
			case 'Unity.MouseMoved':
				this.iFrameMouseMovedEvents.next();
				break;
		}
	}
}

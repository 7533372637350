import { BaseApiService } from './base-api.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserSettingsApiService extends BaseApiService {
	private constructor(private http: HttpClient) {
		super();
	}

	/** Change the user's preferred locale for a course. */
	public setPreferredLocaleForCourse$(courseKey: string, userId: string, body: { preferredLocale: string }): Observable<{ preferredLocale: string }> {
		const path = `users/${userId}/settings/course/${courseKey}/preferredLocale`;

		const options: any = { headers: this.defaultHeaders };
		options.body = body;

		return this.http.request<{ preferredLocale: string }>(
			'PUT',
			this.basePath + path,
			options as object,
		);
	}

	public setPreferredLocaleForCourse(courseKey: string, userId: string, body: { preferredLocale: string }): Promise<{ preferredLocale: string }> {
		return this.setPreferredLocaleForCourse$(courseKey, userId, body).toPromise();
	}

}

<app-card-view [message]="message">
	<a class="back-link" routerLink="/login">Login</a>
	<div class="card-title">Forgot Password</div>
	<p class="card-text">If you can't remember your password, don't worry. Just enter the email
		address you registered with down below and we will send you a link to update your
		credentials.</p>
	<form #changePasswordForm="ngForm" (ngSubmit)="resetPasswordFormSubmit()" class="galacta-form">
		<div class="form-row">
			<div class="col-auto my-1 flex-grow-1">
				<label for="usernameInput">Email</label>
				<input #usernameInput [(ngModel)]="emailAddress" name="username"
					   id="usernameInput" type="email"
					   placeholder="Mail Address" class="form-control" required/>
			</div>
			<div class="col-auto my-1 align-bottom d-flex justify-content-end flex-grow-0 flex-column">
				<button type="submit" class="btn btn-galacta-primary ml-auto"
						[disabled]="!changePasswordForm.form.valid || submitLoadingStatus.isLoading">
					Request
				</button>
			</div>
		</div>
	</form>
</app-card-view>

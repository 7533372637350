import { BaseApiService } from './base-api.service';
import { BlockStateUpdateDto } from './../models/block-state-update-dto.model';
import { CourseCreationDto } from './../models/course-creation-dto.model';
import { CourseDto } from './../models/course-dto.model';
import { CourseUpdateDto } from './../models/course-update-dto.model';
import { EnrollmentCreationDto } from './../models/enrollment-creation-dto.model';
import { InviteSummaryDto } from './../models/invite-summary-dto.model';
import { ListMetadataDto } from './../models/list-metadata-dto.model';
import { PaginatedListMetadataDto } from './../models/paginated-list-metadata-dto.model';
import { PersonalBitmasterDto } from './../models/personal-bitmaster-dto.model';
import { PersonalBlockDetailDto } from './../models/personal-block-detail-dto.model';
import { PersonalCourseDto } from './../models/personal-course-dto.model';
import { UserDto } from './../models/user-dto.model';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CoursesApiService extends BaseApiService {
	private constructor(private http: HttpClient) {
		super();
	}

	/** Get all courses. */
	public getAllCourses$(params: { query?: string, pageNumber?: number, pageSize?: number } = {}): Observable<{ items: Array<CourseDto>, meta: PaginatedListMetadataDto }> {
		const path = `courses`;

		const options: any = { headers: this.defaultHeaders };
		options.params = new HttpParams();

		Object.entries(params)
			.filter(([key, value]) => !!value)
			.forEach(([key, value]) => (options.params = options.params.set(key, value)));

		return this.http.request<{ items: Array<CourseDto>, meta: PaginatedListMetadataDto }>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getAllCourses(params: { query?: string, pageNumber?: number, pageSize?: number } = {}): Promise<{ items: Array<CourseDto>, meta: PaginatedListMetadataDto }> {
		return this.getAllCourses$(params).toPromise();
	}

	/** Create a new course. */
	public createCourse$(body: CourseCreationDto): Observable<CourseDto> {
		const path = `courses`;

		const options: any = { headers: this.defaultHeaders };
		options.body = body;

		return this.http.request<CourseDto>(
			'POST',
			this.basePath + path,
			options as object,
		);
	}

	public createCourse(body: CourseCreationDto): Promise<CourseDto> {
		return this.createCourse$(body).toPromise();
	}

	/** Get all courses that are associated with the current user. */
	public getUserCourses$(): Observable<{ items: Array<PersonalCourseDto>, meta: ListMetadataDto }> {
		const path = `courses/my`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<{ items: Array<PersonalCourseDto>, meta: ListMetadataDto }>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getUserCourses(): Promise<{ items: Array<PersonalCourseDto>, meta: ListMetadataDto }> {
		return this.getUserCourses$().toPromise();
	}

	/** Get a specific course for the admin. */
	public getCourseDetailByKey$(courseKey: string): Observable<CourseDto> {
		const path = `courses/${courseKey}`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<CourseDto>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getCourseDetailByKey(courseKey: string): Promise<CourseDto> {
		return this.getCourseDetailByKey$(courseKey).toPromise();
	}

	/** Update a course details. */
	public updateCourse$(courseKey: string, body: CourseUpdateDto): Observable<CourseDto> {
		const path = `courses/${courseKey}`;

		const options: any = { headers: this.defaultHeaders };
		options.body = body;

		return this.http.request<CourseDto>(
			'PATCH',
			this.basePath + path,
			options as object,
		);
	}

	public updateCourse(courseKey: string, body: CourseUpdateDto): Promise<CourseDto> {
		return this.updateCourse$(courseKey, body).toPromise();
	}

	/** Get course information (especially for enrollment view). */
	public getInviteSummary$(courseKey: string, uid: string): Observable<InviteSummaryDto> {
		const path = `courses/${courseKey}/invite-summary`;

		const options: any = { headers: this.defaultHeaders };
		options.params = new HttpParams();

		options.params = options.params.set('uid', uid);

		return this.http.request<InviteSummaryDto>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getInviteSummary(courseKey: string, uid: string): Promise<InviteSummaryDto> {
		return this.getInviteSummary$(courseKey, uid).toPromise();
	}

	/** Get a specific course that is associated with the current user. */
	public getUserCourseDetailByKey$(courseKey: string): Observable<PersonalCourseDto> {
		const path = `courses/${courseKey}/my`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<PersonalCourseDto>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getUserCourseDetailByKey(courseKey: string): Promise<PersonalCourseDto> {
		return this.getUserCourseDetailByKey$(courseKey).toPromise();
	}

	/** Get details to the bitMaster including the current user's progress. */
	public getPersonalBitmaster$(courseKey: string): Observable<PersonalBitmasterDto> {
		const path = `courses/${courseKey}/bitmaster`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<PersonalBitmasterDto>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getPersonalBitmaster(courseKey: string): Promise<PersonalBitmasterDto> {
		return this.getPersonalBitmaster$(courseKey).toPromise();
	}

	/** Get all students that are enrolled in this cours. */
	public getListOfStudents$(courseKey: string, params: { query?: string, pageNumber?: number, pageSize?: number } = {}): Observable<{ items: Array<UserDto>, meta: PaginatedListMetadataDto }> {
		const path = `courses/${courseKey}/students`;

		const options: any = { headers: this.defaultHeaders };
		options.params = new HttpParams();

		Object.entries(params)
			.filter(([key, value]) => !!value)
			.forEach(([key, value]) => (options.params = options.params.set(key, value)));

		return this.http.request<{ items: Array<UserDto>, meta: PaginatedListMetadataDto }>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getListOfStudents(courseKey: string, params: { query?: string, pageNumber?: number, pageSize?: number } = {}): Promise<{ items: Array<UserDto>, meta: PaginatedListMetadataDto }> {
		return this.getListOfStudents$(courseKey, params).toPromise();
	}

	/** Create an enrollment into the course with the given course-key. If no user with the given email address exists, an invite is sent. */
	public createEnrollment$(courseKey: string, body: EnrollmentCreationDto): Observable<void> {
		const path = `courses/${courseKey}/students`;

		const options: any = { headers: this.defaultHeaders };
		options.body = body;

		return this.http.request<void>(
			'POST',
			this.basePath + path,
			options as object,
		);
	}

	public createEnrollment(courseKey: string, body: EnrollmentCreationDto): Promise<void> {
		return this.createEnrollment$(courseKey, body).toPromise();
	}

	/** Get details to the the block with the given blockKey of the course with the given courseKey */
	public getPersonalBlockDetail$(courseKey: string, blockKey: string): Observable<PersonalBlockDetailDto> {
		const path = `courses/${courseKey}/bitmaster/${blockKey}`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<PersonalBlockDetailDto>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getPersonalBlockDetail(courseKey: string, blockKey: string): Promise<PersonalBlockDetailDto> {
		return this.getPersonalBlockDetail$(courseKey, blockKey).toPromise();
	}

	/** Update the block-state of a specific block of the course for the current user */
	public updateUserBlockState$(courseKey: string, blockKey: string, body: BlockStateUpdateDto): Observable<PersonalBlockDetailDto> {
		const path = `courses/${courseKey}/bitmaster/${blockKey}`;

		const options: any = { headers: this.defaultHeaders };
		options.body = body;

		return this.http.request<PersonalBlockDetailDto>(
			'PATCH',
			this.basePath + path,
			options as object,
		);
	}

	public updateUserBlockState(courseKey: string, blockKey: string, body: BlockStateUpdateDto): Promise<PersonalBlockDetailDto> {
		return this.updateUserBlockState$(courseKey, blockKey, body).toPromise();
	}

}

<div [style.background-image]="'url(' + course.imageUrl + ')'"
	 class="d-flex flex-column">
	<div class="controls">
		<app-locale-dropdown></app-locale-dropdown>
		<div [routerLink]="[course.courseKey, 'manage']" role="button"
			 class="btn-galacta btn-galacta-sm btn-galacta-dark" *ngIf="canManage">
			<fa-icon [icon]="faWrench"></fa-icon>
			<span class="label">Manage</span>
		</div>
	</div>
	<div class="flex-spacer"></div>
	<div class="bottom d-flex flex-column flex-sm-row">
		<div class="courseInfo">
			<div class="flex-spacer"></div>
			<img class="branding-image" [src]="course.brandingImageUrl" alt="">
			<h2>{{ course.courseName | localise }}</h2>
			<div class="galacta-preview-badge" *ngIf="course.isPreviewCourse">Preview</div>
			<h3>{{ course.teaser | localise }}</h3>
		</div>
		<div class="flex-spacer"></div>
		<a *ngIf="course.firstUncompletedBlock as block"
		   [routerLink]="navigationService.getBlockPlaylistLink(course.courseKey, block.info.blockAssignmentKey)"
		   class="align-self-sm-end align-self-stretch">
			<div class="continue-shortcut d-flex flex-column">
				<div class="chapter-label">Chapter {{ block.chapter.ordinal }}</div>
				<div class="chapter-name">{{ block.chapter.name | localise }}</div>
				<app-block-icon-button [blockInfo]="block.info">{{ block.info.name | localise }}</app-block-icon-button>
			</div>
		</a>
	</div>
</div>

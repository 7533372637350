import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	constructor(private router: Router) {}

	public getCourseListLink() {
		return '/';
	}

	public getCourseLink(courseKey: string) {
		return '/' + courseKey;
	}

	public getCourseRoadmapLink(courseKey: string) {
		return '/' + courseKey + '/roadmap';
	}

	public async navigateToLogin() {
		return this.router.navigateByUrl('/login');
	}

	public async navigateToCourseRoadmap(courseKey: string) {
		return this.router.navigateByUrl(this.getCourseRoadmapLink(courseKey));
	}

	public getBlockPlaylistLink(courseKey: string, blockKey: string) {
		return '/' + courseKey + '/play/' + blockKey;
	}

	public async navigateToPlaylistLink(courseKey: string, blockKey: string) {
		return this.router.navigateByUrl(this.getBlockPlaylistLink(courseKey, blockKey));
	}
}

<div class="container-fluid root-container">
	<header>
		<img src="assets/images/logo_white.png" alt="" class="logo"/>
		<app-logged-in-user-tile></app-logged-in-user-tile>
	</header>
	<main>
		<a *ngFor="let courseSummary of (courseSummaries$ | async)"
		   [routerLink]="navigationService.getCourseLink(courseSummary.courseKey)">
			<app-course-tile [course]="courseSummary"></app-course-tile>
		</a>
	</main>
</div>

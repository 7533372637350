import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LocalisedString } from '../../api/utils/localised-string';

@Injectable()
export class CurrentContentLocaleService {
	private _availableLocales$ = new BehaviorSubject(['en']);
	private _currentLocale$ = new BehaviorSubject('en');

	public get availableLocales$() {
		return this._availableLocales$.asObservable();
	}

	public get currentLocale$() {
		return this._currentLocale$.asObservable();
	}

	public setCurrentLocale(locale: string) {
		this._currentLocale$.next(locale);
	}

	public setAvailableLocales(locales: Array<string>) {
		this._availableLocales$.next(locales);
		if (!locales.includes(this._currentLocale$.getValue())) {
			this.setCurrentLocale(locales[0]);
		}
	}

	public translate(s: LocalisedString) {
		return this._currentLocale$.pipe(
			map(cl => s.translation(cl)),
			shareReplay(),
		);
	}
}

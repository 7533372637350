import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { PersonalBitmasterDto } from '../../api/models/personal-bitmaster-dto.model';
import { PersonalCourseDto } from '../../api/models/personal-course-dto.model';
import { CoursesApiService } from '../../api/services/courses.service';
import { PermissionService } from '../../authentication/services/permission.service';

const emptyString = '';
const isNonEmptyString = (x: string) => x !== emptyString;

@Injectable()
export class CurrentCourseService implements OnDestroy {
	public course$: Observable<PersonalCourseDto>;
	public bitmaster$: Observable<PersonalBitmasterDto>;
	public isEnrolled$: Observable<boolean>;
	public canManage$: Observable<boolean>;

	public get courseKey$() {
		return this._courseKey$.asObservable();
	}

	private _courseKey$ = new BehaviorSubject(emptyString);
	private serviceDestroyedNotifier$ = new Subject<boolean>();

	constructor(coursesApiService: CoursesApiService, permissionService: PermissionService) {
		this.course$ = this._courseKey$.pipe(
			filter(isNonEmptyString),
			switchMap(key => coursesApiService.getUserCourseDetailByKey$(key)),
			shareReplay(),
		);
		this.bitmaster$ = this._courseKey$.pipe(
			filter(isNonEmptyString),
			switchMap(key => coursesApiService.getPersonalBitmaster$(key)),
			shareReplay(),
		);
		this.isEnrolled$ = this._courseKey$.pipe(
			filter(isNonEmptyString),
			map(courseKey => permissionService.isEnrolled(courseKey)),
			shareReplay(),
		);
		this.canManage$ = this._courseKey$.pipe(
			filter(isNonEmptyString),
			map(courseKey => permissionService.canManage(courseKey)),
			shareReplay(),
		);
	}

	public init(courseKey$: Observable<string>) {
		courseKey$.pipe(takeUntil(this.serviceDestroyedNotifier$)).subscribe(this._courseKey$);
	}

	public ngOnDestroy(): void {
		this.serviceDestroyedNotifier$.next(true);
	}
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SessionInformationDto } from '../../api/models/session-information-dto.model';
import { LoginView } from '../views/login/login.view';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class SessionTokenInterceptor implements HttpInterceptor {
	constructor(private router: Router, private authenticationService: AuthenticationService) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.url.startsWith(environment.apiBaseUrl)) return next.handle(req);
		if (!this.authenticationService.isAuthenticated) return next.handle(req);

		const sessionInfo = this.authenticationService.sessionInfo as SessionInformationDto;
		const transformedHeaders = req.headers.set('Authorization', 'Bearer ' + sessionInfo.token);

		return next.handle(req.clone({ headers: transformedHeaders })).pipe(
			tap({
				error: error => {
					if (error.status && error.status === 401) {
						if (!this.authenticationService.isAuthenticated) return;
						this.authenticationService.invalidateLocalSession();
						this.router.navigate(['/login'], {
							queryParams: {
								messageCode: LoginView.MessageCodeQueryParameter.SESSION_EXPIRED,
								sourceUrl: this.router.url,
							},
						});
					}
				},
			}),
		);
	}
}

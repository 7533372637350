import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { map } from 'rxjs/operators';
import { PersonalChapterDto } from '../../../api/models/personal-chapter-dto.model';
import { PersonalPhaseDto } from '../../../api/models/personal-phase-dto.model';
import { NavigationService } from '../../../shared/injectables/navigation-service';
import { CurrentCourseService } from '../../services/current-course.service';

@Component({
	selector: 'app-course-resource-overview',
	templateUrl: './course-library.component.html',
	styleUrls: ['./course-library.component.scss'],
})
export class CourseLibraryComponent {
	public chapters$!: Observable<Array<PersonalChapterDto>>;
	public loadingPlaceholderDelayExpired$: Observable<boolean>;

	public courseKey!: string;

	constructor(
		activatedRoute: ActivatedRoute,
		private currentCourseService: CurrentCourseService,
		public navigationService: NavigationService,
	) {
		this.chapters$ = this.currentCourseService.bitmaster$.pipe(
			map(bm => {
				const getChapters = (p: PersonalPhaseDto) => p.chapters;
				return bm.phases.flatMap(getChapters);
			}),
			shareReplay(),
		);

		this.loadingPlaceholderDelayExpired$ = timer(300).pipe(map(() => true));
	}
}

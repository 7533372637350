import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
	selector: 'app-logout-view',
	templateUrl: './logout.view.html',
	styleUrls: ['./logout.view.scss'],
})
export class LogoutView implements OnInit {
	constructor(private authenticationService: AuthenticationService) {}

	public async ngOnInit() {
		await this.authenticationService.logout();

		// forward to root-page and reloading whole application via location.assign()
		// this hard-reload clears eventual memory leaks or state inconsistencies
		location.assign('/');
	}
}

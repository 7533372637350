import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faRocket } from '@fortawesome/pro-regular-svg-icons/faRocket';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown';
import { LoadingStatus } from '../../../shared/util/loading-status';
import { AuthenticationService } from '../../services/authentication.service';

enum MessageCodeQueryParameter {
	SESSION_EXPIRED,
}

@Component({
	selector: 'app-login-view',
	templateUrl: './login.view.html',
	styleUrls: ['./login.view.scss'],
})
export class LoginView implements OnInit {
	public static MessageCodeQueryParameter = MessageCodeQueryParameter;

	public username = '';
	public password = '';
	public message?: { icon?: IconDefinition; text: string } = undefined;
	public submitLoadingStatus = new LoadingStatus();

	public faRocket = faRocket;

	@ViewChild('loginForm')
	private loginForm!: NgForm;
	@ViewChild('passwordInput')
	private passwordInput!: ElementRef;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService,
	) {}

	public ngOnInit() {
		// remove source-url from query-parameters if it can be ignored
		const ignoredSourceUrls = ['', '/', '/logout'];
		const newQueryParams = { ...this.route.snapshot.queryParams };
		if (ignoredSourceUrls.includes(newQueryParams.sourceUrl)) delete newQueryParams.sourceUrl;
		this.router.navigate([], { relativeTo: this.route, queryParams: newQueryParams });

		// set message property of component according to message-code query-parameter
		const messageCodeQueryParameter = parseInt(this.route.snapshot.queryParams.messageCode, 10);
		switch (messageCodeQueryParameter) {
			case MessageCodeQueryParameter.SESSION_EXPIRED:
				this.message = {
					icon: faFrown,
					text:
						'We are sorry - it looks like our server does not remember you. Please log in again.',
				};
		}
	}

	public async loginFormSubmit() {
		const loadingId = this.submitLoadingStatus.startLoadingProcess();

		try {
			await this.authenticationService.login(this.username, this.password);
			this.router.navigateByUrl(this.route.snapshot.queryParams.sourceUrl || '');
		} catch (err) {
			this.message = { icon: faExclamationCircle, text: err.message };
			this.password = '';
			this.loginForm.form.markAsPristine();
			this.passwordInput.nativeElement.focus();
		} finally {
			this.submitLoadingStatus.stopLoadingProcess(loadingId);
		}
	}
}

<div class="container-fluid root-container">
	<header *ngIf="course$ | async as course; else loadingPlaceholder"
			class="d-flex flex-column loaded"
			[style.background-image]="'url(' + course.imageUrl + ')'">
		<div class="top">
			<div class="equal-width mt-3">
				<a [routerLink]="navigationService.getCourseListLink()">
					<app-icon-button [icon]="faChevronLeft" [iconPosition]="0">
						Overview
					</app-icon-button>
				</a>
			</div>
			<div class="equal-width text-center d-md-block d-none">
				<img
					src="{{ course.brandingImageUrl }}"
					height="14" alt="Company Logo">
				<h1>{{ course.courseName | localise }}</h1>
				<div class="galacta-preview-badge" *ngIf="course.isPreviewCourse">Preview</div>
			</div>
			<app-logged-in-user-tile class="equal-width  mt-2"></app-logged-in-user-tile>
		</div>
		<div class="flex-spacer"></div>
		<div class="d-md-none">
			<img
				src="{{ course.brandingImageUrl }}"
				height="14" alt="Company Logo">
			<h1>{{ course.courseName | localise }}</h1>
			<div class="galacta-preview-badge" *ngIf="course.isPreviewCourse">Preview</div>
		</div>
		<nav>
			<ul>
				<ng-container *ngIf="isEnrolled$ | async">
				<li><a routerLink="roadmap" routerLinkActive="active">
					<fa-icon [icon]="faTv"></fa-icon>
					Roadmap</a></li>
				<li><a routerLink="library" routerLinkActive="active">Library</a></li>
				</ng-container>
				<li *ngIf="canManage$ | async"><a routerLink="manage" routerLinkActive="active">Manage</a></li>
			</ul>
			<app-locale-dropdown></app-locale-dropdown>
		</nav>
	</header>

	<router-outlet></router-outlet>
</div>

<ng-template #loadingPlaceholder>
	<header class="loading" *ngIf="loadingPlaceholderDelayExpired$ | async"></header>
</ng-template>

import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faStar as farStar } from '@fortawesome/pro-regular-svg-icons/faStar';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faStar as fasStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { Observable, Subject, timer } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, takeUntil, withLatestFrom } from 'rxjs/operators';
import { PersonalBlockDetailDto } from '../../../api/models/personal-block-detail-dto.model';
import { PersonalBlockSummaryDto } from '../../../api/models/personal-block-summary-dto.model';
import { UserSettingsApiService } from '../../../api/services/user-settings.service';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { IconButtonComponentIconPosition } from '../../../shared/components/icon-button/icon-button.component';
import { CurrentContentLocaleService } from '../../../shared/injectables/current-content-locale.service';
import { NavigationService } from '../../../shared/injectables/navigation-service';
import { CurrentPlaylistService } from '../../services/current-playlist.service';

@Component({
	selector: 'app-chapter-playlist',
	templateUrl: './chapter-playlist.view.html',
	styleUrls: ['./chapter-playlist.view.scss'],
	providers: [CurrentContentLocaleService, CurrentPlaylistService],
})
export class ChapterPlaylistView implements OnDestroy {
	public block$: Observable<PersonalBlockDetailDto>;
	public chapterIsCompleted$: Observable<boolean>;
	public blocksInChapter$: Observable<Array<PersonalBlockSummaryDto>>;
	public loadingPlaceholderDelayExpired = false;

	public IconButtonComponentIconPosition = IconButtonComponentIconPosition;
	public faChevronLeft = faChevronLeft;
	public faChevronRight = faChevronRight;
	public farStar = farStar;
	public fasStar = fasStar;

	private componentDestroyedNotifier$ = new Subject<boolean>();

	constructor(
		private activatedRoute: ActivatedRoute,
		private currentContentLocaleService: CurrentContentLocaleService,
		private authenticationService: AuthenticationService,
		private userSettingsApiService: UserSettingsApiService,
		public currentPlaylistService: CurrentPlaylistService,
		public navigationService: NavigationService,
	) {
		this.currentPlaylistService.init(
			this.activatedRoute.params.pipe(
				map(params => [params.courseKey, params.blockKey] as [string, string]),
				shareReplay(),
			),
		);

		this.block$ = this.currentPlaylistService.currentBlockDetails$;
		this.chapterIsCompleted$ = this.currentPlaylistService.chapterIsCompleted$;
		this.blocksInChapter$ = this.currentPlaylistService.blocks$;
		this.block$
			.pipe(
				distinctUntilChanged((x, y) => x.info.blockId === y.info.blockId),
				takeUntil(this.componentDestroyedNotifier$),
			)
			.subscribe(block => {
				this.currentContentLocaleService.setCurrentLocale(
					block.course.userPreferredLocale || 'en',
				);
				this.currentContentLocaleService.setAvailableLocales(block.course.availableLocales);
			});

		this.currentContentLocaleService.currentLocale$
			.pipe(takeUntil(this.componentDestroyedNotifier$), withLatestFrom(this.block$))
			.subscribe(([locale, block]) => {
				this.userSettingsApiService.setPreferredLocaleForCourse(
					block.course.courseKey,
					this.authenticationService.sessionInfo!.userId,
					{ preferredLocale: locale },
				);
			});

		timer(300)
			.pipe(takeUntil(this.componentDestroyedNotifier$))
			.subscribe(() => (this.loadingPlaceholderDelayExpired = true));
	}

	public ngOnDestroy() {
		this.componentDestroyedNotifier$.next(true);
	}

	public async onBlockCompleted() {
		this.currentPlaylistService.updateCurrentBlockState({
			isCompleted: true,
			updateInteraction: true,
		});
	}

	public async onBlockInteraction() {
		console.log('block interaction');
		this.currentPlaylistService.updateCurrentBlockState({
			updateInteraction: true,
		});
	}

	public async toggleBlockFavourited(isFavourite: boolean) {
		this.currentPlaylistService.updateCurrentBlockState({
			isFavourite: !isFavourite,
			updateInteraction: true,
		});
	}
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthenticatedGuard } from './guards/is-authenticated.guard';
import { IsUnauthenticatedGuard } from './guards/is-unauthenticated.guard';
import { ForgotPasswordView } from './views/forgot-password/forgot-password.view';
import { LoginView } from './views/login/login.view';
import { LogoutView } from './views/logout/logout.view';
import { ResetPasswordView } from './views/reset-password/reset-password.view';

const authenticationRoutes: Routes = [
	{
		path: 'login',
		canActivate: [IsUnauthenticatedGuard],
		component: LoginView,
	},
	{
		path: 'logout',
		canActivate: [IsAuthenticatedGuard],
		component: LogoutView,
	},
	{
		path: 'forgot-password',
		canActivate: [IsUnauthenticatedGuard],
		component: ForgotPasswordView,
	},
	{
		path: 'reset-password',
		component: ResetPasswordView,
	},
];

@NgModule({
	imports: [RouterModule.forChild(authenticationRoutes)],
	exports: [RouterModule],
})
// tslint:disable-next-line: no-unnecessary-class
export class AuthenticationRoutingModule {}

<img
	[src]="backgroundImageUrl$ | async"
	#backgroundImage id="backgroundImage" role="none"/>
<div id="backgroundOverlay" role="none"></div>
<div class="container-fluid root-container d-flex flex-column" *ngIf="course$ | async as course">
	<div class="flex-spacer"></div>
	<div class="text-center">
		<img src="{{ course.brandingImageUrl }}" height="14" alt="Company Logo">
		<h1>{{ course.courseName | localise }}</h1>
		<div class="galacta-preview-badge" *ngIf="course.isPreviewCourse">Preview</div>
	</div>
	<p class="my-4">{{ course.description | localise }}</p>
	<div class="d-flex flex-row justify-content-between mb-5">
		<div class="stat">
			<div>
				<fa-icon [icon]="faBooks"></fa-icon>
			</div>
			<div>
				<div>{{ course.chapterCount }}</div>
				<div>chapters</div>
			</div>
		</div>
		<div class="stat">
			<div>
				<fa-icon [icon]="faTv"></fa-icon>
			</div>
			<div>
				<div>{{ videoCount$ | async }}</div>
				<div>videos</div>
			</div>
		</div>
		<div class="stat">
			<div>
				<fa-icon [icon]="faTrophy"></fa-icon>
			</div>
			<div>
				<div>{{ gameCount$ | async }}</div>
				<div>games</div>
			</div>
		</div>
	</div>
	<div class="d-flex" *ngIf="state === 'authenticated'">
		<button type="button" id="enroll-authenticated"
				class="btn btn-galacta-primary mx-auto"
				(click)="authenticatedEnroll()"
				style="font-size: 120%;">
			<fa-icon [icon]="faRocket"></fa-icon>
			<span class="ml-2"
				  style="font-weight: bold; text-transform: uppercase; letter-spacing: 1px; font-size: 90%;">Jump right in!</span>
		</button>
	</div>
	<div class="d-flex actions" *ngIf="state === 'start'">
		<div class="d-flex align-items-center align-self-center" (click)="watchFirstVideo()"
			 role="button">
			<fa-icon [icon]="faPlayCircle"></fa-icon>
			<div class="text-center mx-2 my-0">Start by watching the first video</div>
		</div>
		<div class="d-flex flex-column action-separator align-items-center mx-2">
			<div class="flex-spacer"></div>
			<div>or</div>
			<div class="flex-spacer"></div>
		</div>
		<div class="d-flex align-items-center align-self-center" (click)="register()" role="button">
			<div class="text-center mx-2 my-0">Start by telling us about yourself</div>
			<fa-icon [icon]="faUserAstronaut"></fa-icon>
		</div>
	</div>
	<ng-container *ngIf="state === 'firstBlock'">
		<app-block-container id="enroll-first-video"
							 [blockContent]="course.firstBlockContent"
							 (blockCompleted)="hasCompletedFirstBlock = true; register()"></app-block-container>
		<p (click)="register()" class="mt-2 text-center"
		   style="text-decoration: underline; font-size: .9em; cursor: pointer;">
			Skip Video
		</p>
	</ng-container>
	<form *ngIf="state === 'register'" class="galacta-card p-3 galacta-form was-validated"
		  (ngSubmit)="sendRegistration()" id="enroll-sign-up">
		<div class="form-group">
			<label for="firstName">First Name</label>
			<input type="text" class="form-control" id="firstName" name="firstName"
				   placeholder="First Name" [(ngModel)]="registerModel.firstName">
			<div class="invalid-feedback">
				Please tell us your first name.
			</div>
		</div>
		<div class="form-group">
			<label for="lastName">Last Name</label>
			<input type="text" class="form-control" id="lastName" name="lastName"
				   placeholder="Last Name" [(ngModel)]="registerModel.lastName">
			<div class="invalid-feedback">
				Please tell us your last name.
			</div>
		</div>
		<div class="form-group">
			<label for="password1">Your Password
				<small>Minimum 10 characters.</small>
			</label>
			<input type="password" class="form-control" id="password1" name="password1"
				   placeholder="Password" [(ngModel)]="registerModel.password1">
			<div class="is-invalid"
				 *ngIf="registerModel.password1.length < 10 && registerModel.password1.length > 0">
				Please choose a secure password with at least 10 characters.
			</div>
		</div>
		<div class="form-group">
			<label for="password2">Repeat your Password</label>
			<input type="password" class="form-control" id="password2" name="password2"
				   placeholder="Password" [(ngModel)]="registerModel.password2">
			<div class="is-invalid" *ngIf="passwordsDontMatch">
				Both passwords must match!
			</div>
		</div>
		<div class="form-group mt-4 mb-0 d-flex">
			<div class="form-check">
				<input class="form-check-input" type="checkbox" value="" id="defaultCheck2"
					   name="tos" [(ngModel)]="registerModel.tos">
				<label class="form-check-label" for="defaultCheck2">
					I accept the <a href="https://www.galacta.com/privacy.html">Terms of Service</a>
				</label>
			</div>
			<button type="submit" class="btn btn-galacta-primary ml-auto"
					[disabled]="!formIsValid">
				<fa-icon [icon]="faRocket"></fa-icon>
				<span class="ml-2">Submit</span>
			</button>
		</div>
	</form>
	<div class="flex-spacer"></div>
</div>

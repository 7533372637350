<!-- onclick is needed for it to work is iOS Safari. Otherwise the hover/focus
     event isn't recognised on mobile. -->
<div class="container" onclick="void(0)">
	<div class="userInfo">
		<div class="name">{{ displayedUser.name }}</div>
		<div class="email">{{ displayedUser.emailAddress }}</div>
	</div>
	<fa-icon [icon]="faUserAstronaut" size="2x"></fa-icon>
	<div class="user-dropdown">
		<div class="menu-item"><a routerLink="/settings" [queryParams]="{ returnUrl: returnUrl }">Settings</a></div>
		<div class="menu-item"><a routerLink="/logout">Logout</a></div>
	</div>
</div>

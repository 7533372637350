import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-error-view',
	templateUrl: './error.view.html',
	styleUrls: ['./error.view.scss'],
})
export class ErrorView {
	public location: Location;
	public errorCode = 'Ooops!';
	public errorMessage = 'It looks like something went wrong - we are sorry.';

	constructor(location: Location, route: ActivatedRoute) {
		this.location = location;
		if (route.snapshot.data.code) this.errorCode = route.snapshot.data.code;
		if (route.snapshot.data.message) this.errorMessage = route.snapshot.data.message;
	}
}

import { Component, HostBinding, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export enum IconButtonComponentIconPosition {
	Left,
	Right,
}

@Component({
	selector: 'app-icon-button',
	templateUrl: './icon-button.component.html',
	styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
	@Input()
	public icon!: IconDefinition;
	@Input()
	public iconPosition = IconButtonComponentIconPosition.Left;

	@HostBinding('attr.role')
	public role = 'button';

	@HostBinding('class.icon-reverse')
	public get iconReverse() {
		return this.iconPosition === IconButtonComponentIconPosition.Right;
	}
}

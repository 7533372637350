<div class="block-component-wrapper">
	<div class="control-container">
		<a *ngIf="containerSettings.showPreviousBlockControl && (previousBlock$ | async) as previousBlock"
		   class="next-block-button control-element"
		   [routerLink]="['..', previousBlock!.info.blockAssignmentKey]">
			<fa-icon [icon]="faChevronLeft"></fa-icon>
			{{ previousBlock!.info.name | localise }}
		</a>

		<div class="flex-spacer"></div>

		<button *ngIf="containerSettings.showFullscreenControl"
				class="enable-fullscreen-button control-element"
				(click)="enableFullscreenComponent()">
			<fa-icon [icon]="faExpandAlt"></fa-icon>
		</button>
		<button *ngIf="containerSettings.showFullscreenControl"
				class="disable-fullscreen-button control-element"
				(click)="disableFullscreenComponent()">
			<fa-icon [icon]="faCompressAlt"></fa-icon>
		</button>
		<a *ngIf="(canGoToNextBlock$ | async) && containerSettings.showNextBlockControl && (nextBlock$ | async) as nextBlock"
		   class="next-block-button control-element"
		   [class.show-always]="!!nextBlockCountdown$"
		   [routerLink]="['..', nextBlock!.info.blockAssignmentKey]">
			{{ nextBlock!.info.name | localise }}
			<ng-container *ngIf="nextBlockCountdown$ | async as remainingSeconds">in {{ remainingSeconds }} </ng-container>
			<fa-icon [icon]="faChevronRight"></fa-icon>
		</a>
	</div>

	<ng-container [ngSwitch]="blockContent.type">
		<app-vimeo-block *ngSwitchCase="BlockType.VIMEO" [blockContent]="blockContent"
						 (blockCompleted)="onChildComponentBlockCompleted()"
						 (blockInteraction)="onChildComponentBlockInteraction()"
						 (startNextBlockCountdown)="onStartNextBlockCountdownEvent()"
						 (stopNextBlockCountdown)="onStopNextBlockCountdownEvent()"
						 (changeContainerSettings)="onChangeContainerSettingsEvent($event)"></app-vimeo-block>

		<app-storyline-block *ngSwitchCase="BlockType.STORYLINE" [blockContent]="blockContent"
							 (blockCompleted)="onChildComponentBlockCompleted()"
							 (blockInteraction)="onChildComponentBlockInteraction()"
							 (startNextBlockCountdown)="onStartNextBlockCountdownEvent()"
							 (stopNextBlockCountdown)="onStopNextBlockCountdownEvent()"
							 (changeContainerSettings)="onChangeContainerSettingsEvent($event)"
							 (navigateToNextBlock)="onNavigateToNextBlock()"
							 (navigateToPreviousBlock)="onNavigateToPreviousBlock()"></app-storyline-block>

		<app-unity-block *ngSwitchCase="BlockType.UNITY" [blockContent]="blockContent"
			(blockCompleted)="onChildComponentBlockCompleted()"
			(blockInteraction)="onChildComponentBlockInteraction()"
			(changeContainerSettings)="onChangeContainerSettingsEvent($event)"
			(navigateToNextBlock)="onNavigateToNextBlock()"
			(navigateToPreviousBlock)="onNavigateToPreviousBlock()"></app-unity-block>

		<div *ngSwitchDefault class="default-block-component">
			<div class="icon">
				<fa-icon [icon]="faFrown" size="4x"></fa-icon>
			</div>
			<div>No component for block of type {{ blockContent.type }} found.</div>
		</div>
	</ng-container>
</div>

<div class="row">
	<div class="col-12 mb-3">
		<div class="galacta-card library-info">
			This is the Library. Every lesson you have completed will appear here, divided into the
			three workspaces <span class="classroom-text">Classroom</span>, <span
			class="workshop-text">Workshop</span>, and <span class="project-text">Project</span>.
		</div>
	</div>
</div>

<ng-container *ngIf="chapters$ | async as chapters; else loadingPlaceholder">
	<div class="row">
		<div *ngFor="let chapter of chapters" class="col-12 mb-2">
			<app-course-library-chapter-row [personalChapter]="chapter">
			</app-course-library-chapter-row>
		</div>
	</div>
</ng-container>

<ng-template #loadingPlaceholder>
	<div class="loading-placeholder-container row" *ngIf="loadingPlaceholderDelayExpired$ | async">
		<div *ngFor="let index of [1,2,3]" class="col-12 mb-2">
			<app-course-library-chapter-row [personalChapter]="null">
			</app-course-library-chapter-row>
		</div>
	</div>
</ng-template>

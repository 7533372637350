import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons/faShieldCheck';
import { faCloud } from '@fortawesome/pro-solid-svg-icons/faCloud';
import { forkJoin, Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersApiService } from '../../../api/services/users.service';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { LoadingStatus } from '../../../shared/util/loading-status';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.view.html',
	styleUrls: ['./settings.view.scss'],
})
export class SettingsView {
	public message?: { icon?: IconDefinition; text: string } = undefined;

	public firstname: string;
	public lastname: string;
	public email: string;

	public submitLoadingStatus = new LoadingStatus();
	public backUrl$: Observable<string>;

	public faCloud = faCloud;
	public faEnvelope = faEnvelope;

	constructor(
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private userApiService: UsersApiService,
	) {
		const sessionInfo = this.authenticationService.sessionInfo!;
		this.firstname = sessionInfo.firstName;
		this.lastname = sessionInfo.lastName;
		this.email = sessionInfo.emailAddress;

		this.backUrl$ = this.route.queryParams.pipe(map(params => params.returnUrl || '/'));
	}

	public saveName() {
		const loadingId = this.submitLoadingStatus.startLoadingProcess();

		const updateUser$ = this.userApiService.updateUser$(
			this.authenticationService.sessionInfo!.userId,
			{
				firstName: this.firstname,
				lastName: this.lastname,
			},
		);
		const subscription = forkJoin([updateUser$, timer(1000)])
			.pipe(map(([a]) => a))
			.subscribe(updatedUser => {
				this.authenticationService.updateUserInSessionInfo(updatedUser);
				this.message = {
					icon: faShieldCheck,
					text: 'Your settings have been saved successfully!',
				};
				subscription.unsubscribe();
				this.submitLoadingStatus.stopLoadingProcess(loadingId);
			});
	}

	public requestPasswordResetLink() {
		const loadingId = this.submitLoadingStatus.startLoadingProcess();

		const email = this.authenticationService.sessionInfo!.emailAddress;
		const request$ = this.userApiService.requestPasswordReset$(email);
		const subscription = forkJoin([request$, timer(1000)])
			.pipe(map(([a]) => a))
			.subscribe(() => {
				this.message = {
					icon: faEnvelope,
					text:
						"Sent! You will receive a mail in the next couple of minutes. If it doesn't show, have a look in your spam folder.",
				};
				subscription.unsubscribe();
				this.submitLoadingStatus.stopLoadingProcess(loadingId);
			});
	}
}

import { LocalisedStringDto } from './../models/localised-string-dto.model';

export enum Type {
	VIMEO = 'VIMEO',
	MARKDOWN = 'MARKDOWN',
	STORYLINE = 'STORYLINE',
	UNITY = 'UNITY',
}

export interface PersonalVimeoBlockContentDto {
	type: Type;
	vimeoId: LocalisedStringDto[];
}

import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export class BaseApiService {
	protected get basePath(): string {
		if (!environment.apiBaseUrl) {
			throw new Error(`Base Api URL hasn't been defined in the environment!`);
		}
		return `${environment.apiBaseUrl}`;
	}

	protected get defaultHeaders(): HttpHeaders {
		return new HttpHeaders();
	}
}

import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { UsersApiService } from '../../../api/services/users.service';
import { LoadingStatus } from '../../../shared/util/loading-status';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.view.html',
	styleUrls: ['./forgot-password.view.scss'],
})
export class ForgotPasswordView {
	public emailAddress = '';
	public message?: { icon?: IconDefinition; text: string } = undefined;
	public submitLoadingStatus = new LoadingStatus();

	@ViewChild('changePasswordForm')
	private changePasswordForm!: NgForm;

	constructor(private userApiService: UsersApiService) {}

	public async resetPasswordFormSubmit() {
		const loadingId = this.submitLoadingStatus.startLoadingProcess();

		try {
			await this.userApiService.requestPasswordReset(this.emailAddress);
			this.message = {
				icon: faEnvelope,
				text:
					"Sent! You will receive a mail in the next couple of minutes. If it doesn't show, have a look in your spam folder.",
			};
		} catch (err) {
			this.message = {
				icon: faExclamationCircle,
				text:
					'We could not find a user with that address. Are you sure the address you have entered is the one you are registered with?',
			};
			this.changePasswordForm.form.markAsPristine();
		} finally {
			this.submitLoadingStatus.stopLoadingProcess(loadingId);
		}
	}
}

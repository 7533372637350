import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
	filter,
	map,
	shareReplay,
	switchMap,
	take,
	takeUntil,
	withLatestFrom,
} from 'rxjs/operators';
import { BlockStateUpdateDto } from '../../api/models/block-state-update-dto.model';
import { CourseSummaryDto } from '../../api/models/course-summary-dto.model';
import { PersonalBlockDetailDto } from '../../api/models/personal-block-detail-dto.model';
import { PersonalBlockSummaryDto } from '../../api/models/personal-block-summary-dto.model';
import { PersonalChapterDto } from '../../api/models/personal-chapter-dto.model';
import { CoursesApiService } from '../../api/services/courses.service';
import { NavigationService } from '../../shared/injectables/navigation-service';

const emptyArray: [string, string] = ['', ''];
const isNonEmptyArray = (x: [string, string]) => x !== emptyArray;

@Injectable()
export class CurrentPlaylistService implements OnDestroy {
	public blockKey$: Observable<string>;
	public courseKey$: Observable<string>;

	public currentBlockDetails$: Observable<PersonalBlockDetailDto>;
	public currentBlockIndex$: Observable<number>;
	public currentBlockSummary$: Observable<PersonalBlockSummaryDto>;
	public nextBlockSummary$: Observable<PersonalBlockSummaryDto | undefined>;
	public previousBlockSummary$: Observable<PersonalBlockSummaryDto | undefined>;

	public chapter$: Observable<PersonalChapterDto>;
	public course$: Observable<CourseSummaryDto>;
	public blocks$: Observable<Array<PersonalBlockSummaryDto>>;
	public canGoToPreviousBlock$: Observable<boolean>;
	public canGoToNextBlock$: Observable<boolean>;
	public chapterIsCompleted$: Observable<boolean>;

	private _keys$ = new BehaviorSubject(emptyArray);
	private _blockDetails$ = new BehaviorSubject<PersonalBlockDetailDto>(
		(undefined as unknown) as PersonalBlockDetailDto,
	);
	private serviceDestroyedNotifier$ = new Subject<boolean>();

	constructor(
		private coursesApiService: CoursesApiService,
		private navigationService: NavigationService,
	) {
		this.courseKey$ = this._keys$.pipe(
			map(k => k[0]),
			shareReplay(1),
		);
		this.blockKey$ = this._keys$.pipe(
			map(k => k[1]),
			shareReplay(1),
		);
		this._keys$
			.pipe(
				filter(isNonEmptyArray),
				switchMap(([courseKey, blockKey]) =>
					coursesApiService.getPersonalBlockDetail$(courseKey, blockKey),
				),
			)
			.subscribe(this._blockDetails$);

		this.currentBlockDetails$ = this._blockDetails$.pipe(
			filter(x => !!x),
			shareReplay(1),
		);

		this.chapter$ = this.currentBlockDetails$.pipe(
			map(block => block.chapter),
			shareReplay(1),
		);
		this.course$ = this.currentBlockDetails$.pipe(
			map(block => block.course),
			shareReplay(1),
		);
		this.blocks$ = this.chapter$.pipe(
			map(chapter => chapter.sections.flatMap(s => s.blocks)),
			shareReplay(1),
		);
		this.currentBlockSummary$ = this.currentBlockDetails$.pipe(
			map(
				details =>
					({
						info: details.info,
						state: details.state,
					} as PersonalBlockSummaryDto),
			),
			shareReplay(1),
		);
		this.currentBlockIndex$ = combineLatest([this.blocks$, this.currentBlockSummary$]).pipe(
			map(([blocks, currentSummary]) =>
				blocks.findIndex(
					bl => bl.info.blockAssignmentId === currentSummary.info.blockAssignmentId,
				),
			),
			shareReplay(1),
		);
		this.nextBlockSummary$ = this.currentBlockIndex$.pipe(
			withLatestFrom(this.currentBlockDetails$, this.blocks$),
			map(([currentBlockIndex, currentBlockDetails, blocks]) => {
				if (currentBlockIndex < blocks.length - 1) {
					return blocks[currentBlockIndex + 1];
				} else {
					return currentBlockDetails.firstBlockOfNextChapter;
				}
			}),
			shareReplay(1),
		);
		this.previousBlockSummary$ = this.currentBlockIndex$.pipe(
			withLatestFrom(this.currentBlockDetails$, this.blocks$),
			map(([currentBlockIndex, currentBlockDetails, blocks]) => {
				if (currentBlockIndex > 0) {
					return blocks[currentBlockIndex - 1];
				} else {
					return currentBlockDetails.lastBlockOfPreviousChapter;
				}
			}),
			shareReplay(1),
		);
		this.chapterIsCompleted$ = this.blocks$.pipe(
			map(blocks => !blocks.map(b => b.state.isCompleted).includes(false)),
			shareReplay(),
		);
		this.canGoToNextBlock$ = combineLatest([
			this.nextBlockSummary$,
			this.chapterIsCompleted$,
		]).pipe(
			withLatestFrom(this.currentBlockDetails$),
			map(
				([[nextBlock, chapterIsCompleted], currentBlockDetails]) =>
					chapterIsCompleted || nextBlock !== currentBlockDetails.firstBlockOfNextChapter,
			),
			shareReplay(1),
		);
		this.canGoToPreviousBlock$ = this.previousBlockSummary$.pipe(
			map(prevBlock => prevBlock !== undefined),
			shareReplay(1),
		);
	}

	public navigateToNextBlock() {
		this.nextBlockSummary$
			.pipe(withLatestFrom(this.courseKey$), take(1))
			.subscribe(([nextBlock, courseKey]) => {
				this.navigationService.navigateToPlaylistLink(
					courseKey,
					nextBlock!.info.blockAssignmentKey,
				);
			});
	}

	public navigateToPreviousBlock() {
		combineLatest([this.courseKey$, this.previousBlockSummary$])
			.pipe(take(1))
			.subscribe(([courseKey, prevBlock]) => {
				this.navigationService.navigateToPlaylistLink(
					courseKey,
					prevBlock!.info.blockAssignmentKey,
				);
			});
	}

	public getLinkToBlock(blockId: string) {
		return this.currentBlockDetails$.pipe(
			map(currentBlockDetails =>
				this.navigationService.getBlockPlaylistLink(
					currentBlockDetails.course.courseKey,
					blockId,
				),
			),
		);
	}

	public updateCurrentBlockState(state: BlockStateUpdateDto) {
		this.coursesApiService
			.updateUserBlockState$(
				this._blockDetails$.getValue().course.courseKey,
				this._blockDetails$.getValue().info.blockAssignmentKey,
				state,
			)
			.subscribe(data => this._blockDetails$.next(data));
	}

	public init(keys$: Observable<[string, string]>) {
		keys$.pipe(takeUntil(this.serviceDestroyedNotifier$)).subscribe(this._keys$);
	}

	public ngOnDestroy(): void {
		this.serviceDestroyedNotifier$.next(true);
	}
}

<div ngbDropdown #drop="ngbDropdown" placement="bottom-right"
	 class="d-inline-block galacta-dropdown">
	<div (click)="$event.stopPropagation(); $event.preventDefault()" ngbDropdownToggle
		 class="btn btn-galacta-sm btn-galacta-dark" role="button" id="dropdownMenuButton"
		 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
		<span class="flag-icon flag-icon-{{ (currentLocale$ | async).flag }}"></span><span
		class="label">{{ (currentLocale$ | async).language }}</span>
	</div>
	<div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
		<a *ngFor="let locale of availableLocales$ | async"
		   (click)="$event.stopPropagation(); $event.preventDefault(); drop.close(); choose(locale.locale)"
		   class="dropdown-item"><span class="flag-icon flag-icon-{{ locale.flag }}"></span><span
			class="label">{{ locale.language }}</span></a>
	</div>
</div>

<div class="container h-100">
	<div class="row h-100">
		<div class="mx-auto content-container align-self-center mx-2">
			<div class="text-center">
				<img src="../../../../assets/images/logo_white.png" class="mb-4">
			</div>

			<div *ngIf="message" class="card message-card mb-3">
				<div class="card-body d-flex">
					<div *ngIf="message?.icon" class="message-icon align-self-center mr-3">
						<fa-icon [icon]="message?.icon" size="lg"></fa-icon>
					</div>
					<div class="message-text">
						{{ message?.text }}
					</div>
				</div>
			</div>

			<div class="card galacta-card content-card">
				<div class="card-body">
					<ng-content select="a.back-link"></ng-content>
					<ng-content select=".card-title"></ng-content>
					<ng-content></ng-content>
				</div>
			</div>

		</div>
	</div>
</div>

import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { faWrench } from '@fortawesome/pro-solid-svg-icons/faWrench';
import { Subject } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { PersonalCourseDto } from '../../../api/models/personal-course-dto.model';
import { UserSettingsApiService } from '../../../api/services/user-settings.service';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { PermissionService } from '../../../authentication/services/permission.service';
import { CurrentContentLocaleService } from '../../../shared/injectables/current-content-locale.service';
import { NavigationService } from '../../../shared/injectables/navigation-service';

@Component({
	selector: 'app-course-tile',
	templateUrl: './course-tile.component.html',
	styleUrls: ['./course-tile.component.scss'],
	providers: [CurrentContentLocaleService],
})
export class CourseTileComponent implements OnChanges, OnDestroy {
	@Input()
	public course!: PersonalCourseDto;

	public faWrench = faWrench;

	private componentDestroyedNotifier$ = new Subject<boolean>();

	constructor(
		private permissionService: PermissionService,
		private currentContentLocaleService: CurrentContentLocaleService,
		private authenticationService: AuthenticationService,
		private userSettingsApiService: UserSettingsApiService,
		public navigationService: NavigationService,
	) {
		this.currentContentLocaleService.currentLocale$
			.pipe(
				takeUntil(this.componentDestroyedNotifier$),
				skipWhile(() => !this.course),
			)
			.subscribe(locale => {
				this.userSettingsApiService.setPreferredLocaleForCourse(
					this.course.courseKey,
					this.authenticationService.sessionInfo!.userId,
					{ preferredLocale: locale },
				);
			});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.course) {
			const course = changes.course.currentValue;
			this.currentContentLocaleService.setCurrentLocale(
				course.userPreferredLocale || course.availableLocales[0] || 'en',
			);
			this.currentContentLocaleService.setAvailableLocales(course.availableLocales);
		}
	}

	public ngOnDestroy() {
		this.componentDestroyedNotifier$.next(true);
	}

	public get canManage() {
		return this.permissionService.canManage(this.course.courseId);
	}
}

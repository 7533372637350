import { Injectable } from '@angular/core';
import { SessionCredentialsDto } from '../../api/models/session-credentials-dto.model';
import { SessionInformationDto } from '../../api/models/session-information-dto.model';
import { UserDto } from '../../api/models/user-dto.model';
import { SessionApiService } from '../../api/services/session.service';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	private STORAGE_KEY = 'galacta-webapp-authentication-info';
	private sessionInformationDto: SessionInformationDto | undefined;

	constructor(private sessionApiService: SessionApiService) {}

	public async login(username: string, password: string) {
		try {
			const credentials: SessionCredentialsDto = { username, password };
			const sessionInfo = await this.sessionApiService.createSession(credentials);
			localStorage.setItem(this.STORAGE_KEY, JSON.stringify(sessionInfo));
		} catch (err) {
			if (err.status === 400) throw new Error('Request was rejected by the server.');
			if (err.status === 401) throw new Error('Incorrect username or password.');
			else throw new Error('Unknown error while communicating with the server.');
		}
	}

	public async logout() {
		try {
			await this.sessionApiService.endSession();
		} finally {
			this.invalidateLocalSession();
		}
	}

	public invalidateLocalSession() {
		localStorage.removeItem(this.STORAGE_KEY);
		this.sessionInformationDto = undefined;
	}

	public async updateSessionInfo() {
		const sessionInfo = await this.sessionApiService.getCurrentSession();
		this.invalidateLocalSession();
		localStorage.setItem(this.STORAGE_KEY, JSON.stringify(sessionInfo));
	}

	public get sessionInfo() {
		if (!this.sessionInformationDto) {
			const sessionInfoString = localStorage.getItem(this.STORAGE_KEY);
			if (sessionInfoString) this.sessionInformationDto = JSON.parse(sessionInfoString);
		}

		return this.sessionInformationDto;
	}

	public updateUserInSessionInfo(user: UserDto) {
		const sessionInfo = this.sessionInfo!;
		sessionInfo.userId = user.id;
		sessionInfo.firstName = user.firstName;
		sessionInfo.lastName = user.lastName;
		sessionInfo.emailAddress = user.mailAddress;
		localStorage.setItem(this.STORAGE_KEY, JSON.stringify(sessionInfo));
	}

	public hasSessionScope(scope: string) {
		if (!this.sessionInfo) return false;
		return this.sessionInfo!.scope.includes(scope);
	}

	public get isAuthenticated() {
		return !!this.sessionInfo;
	}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PlaylistModule } from '../playlist/playlist.module';
import { SharedModule } from '../shared/shared.module';
import { UserModule } from '../user/user.module';
import { CourseLibraryChapterRowComponent } from './components/course-library-chapter-row/course-library-chapter-row.component';
import { CourseLibraryComponent } from './components/course-library/course-library.component';
import { CourseManageComponent } from './components/course-manage/course-manage.component';
import { CourseRoadmapComponent } from './components/course-roadmap/course-roadmap.component';
import { CourseTileComponent } from './components/course-tile/course-tile.component';
import { CourseRoutingModule } from './course-routing.module';
import { CourseDetailsView } from './views/course-details/course-details.view';
import { CourseListView } from './views/course-list/course-list.view';
import { EnrollView } from './views/enroll/enroll.view';

@NgModule({
	declarations: [
		CourseListView,
		CourseDetailsView,
		CourseRoadmapComponent,
		CourseLibraryComponent,
		CourseManageComponent,
		CourseTileComponent,
		EnrollView,
		CourseLibraryChapterRowComponent,
	],
	imports: [
		FontAwesomeModule,
		CommonModule,
		SharedModule,
		UserModule,
		PlaylistModule,
		CourseRoutingModule,
		FormsModule,
		NgbTooltipModule,
	],
})
// tslint:disable-next-line: no-unnecessary-class
export class CourseModule {}

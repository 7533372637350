<ng-container *ngIf="personalBitmaster$ | async as bm; else loadingPlaceholder">
	<div class="phase-row mb-3" *ngFor="let phase of bm.phases">
		<div class="row phase-row-header">
			<div class="col-12 mb-1">{{ phase.ordinal }}. {{ phase.name | localise }}</div>
		</div>
		<div class="row phase-row-content">
			<div class="col-lg-4 col-12 mb-2" *ngFor="let chapter of phase.chapters">
				<div class="chapter-tile galacta-card"
					 [class.inaccessible]="!isChapterAccessible(chapter)">
					<div class="chapter-label">Chapter {{ chapter.ordinal }}</div>
					<div class="chapter-name">{{ chapter.name | localise }}</div>
					<div class="chapter-blocks">
						<ng-container *ngFor="let section of chapter.sections">
							<ng-container *ngFor="let block of section.blocks">
								<a [routerLink]="isChapterAccessible(chapter) ? navigationService.getBlockPlaylistLink(courseKey, block.info.blockAssignmentKey) : undefined">
									<app-block-icon-button [blockInfo]="block.info"
														   [blockIsCompleted]="block.state.isCompleted">
									</app-block-icon-button>
								</a>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="phase-row mb-3"
		 *ngFor="let index of (showCommingSoonPhases$ | async)">
		<div class="row phase-row-header">
			<div class="col-12 mb-1">{{ index }}. Phase</div>
		</div>
		<div class="row phase-row-content">
			<div class="col-lg-4 col-12 mb-2" *ngFor="let chapter of [1,2,3]">
				<div class="chapter-tile galacta-card"
					 [class.inaccessible]="true">
					<div class="chapter-label">Chapter</div>
					<div class="chapter-name" style="font-weight: normal; font-style: italic;">Coming soon</div>
					<div class="chapter-blocks">
						<div *ngFor="let x of [1,2,3,4,5,6,7,8,9]" style="width: 25px; height: 25px; display: inline-block; background-color: #888; margin-left: 4px; border-radius: 3px"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #loadingPlaceholder>
	<div class="loading-placeholder-container row" *ngIf="loadingPlaceholderDelayExpired$ | async">
		<div class="col-lg-4 col-12 mb-5" *ngFor="let index of [1,2,3,4,5,6,7,8,9]">
			<div class="loading-placeholder-block galacta-card">
				<div class="label-placeholder"></div>
				<div class="name-placeholder"></div>
			</div>
		</div>
	</div>
</ng-template>

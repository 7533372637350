<app-card-view [message]="message">
	<a class="back-link" [routerLink]="backUrl$ | async">Back</a>
	<div class="card-title">Settings</div>
	<p class="card-text">Change your name or update your password. Currently you cannot change your
		email address. If you think a change necessary, please contact the <a
			href="mailto: support@galacta.com" style="text-decoration: underline;">support team</a>.
	</p>
	<form #settingsForm="ngForm" (ngSubmit)="saveName()" class="galacta-form">
		<div class="form-group">
			<label for="firstnameInput">First Name</label>
			<input #firstnameInput [(ngModel)]="firstname" name="firstname"
				   id="firstnameInput" type="text"
				   placeholder="First Name" class="form-control" required/>
		</div>
		<div class="form-group">
			<label for="lastnameInput">Last Name</label>
			<input #lastnameInput [(ngModel)]="lastname" name="lastname"
				   id="lastnameInput" type="text"
				   placeholder="Last Name" class="form-control" required/>
		</div>
		<div class="form-group mt-3 mb-0 d-flex flex-column flex-md-row">
			<button type="submit" class="btn btn-galacta-primary ml-auto"
					[disabled]="!settingsForm.form.valid || submitLoadingStatus.isLoading">
				<fa-icon [icon]="faCloud"></fa-icon>
				<span class="ml-2">Save</span>
			</button>
		</div>
	</form>
	<p class="card-text mt-3">To change your password, please click the button below. We will send
		an email to <strong>{{ email }}</strong> with a reset link.</p>
	<button type="submit" class="btn btn-galacta-dark w-100"
			(click)="requestPasswordResetLink()"
			[disabled]="!settingsForm.form.valid || submitLoadingStatus.isLoading">
		<fa-icon [icon]="faEnvelope"></fa-icon>
		<span class="ml-2">Request Password Reset Link</span>
	</button>
</app-card-view>

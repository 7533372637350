import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorView } from './views/error/error.view';

const errorRoutes: Routes = [
	{
		path: 'error/401',
		component: ErrorView,
		data: { code: 401, message: 'You need to be logged in to see this page.' },
	},
	{
		path: 'error/403',
		component: ErrorView,
		data: { code: 403, message: 'You do not have sufficient rights to see this page.' },
	},
	{
		path: 'error/404',
		component: ErrorView,
		data: { code: 404, message: 'The requested page was not found.' },
	},
	{
		path: 'error/:errorCode',
		component: ErrorView,
	},
];

@NgModule({
	imports: [RouterModule.forChild(errorRoutes)],
	exports: [RouterModule],
})
// tslint:disable-next-line: no-unnecessary-class
export class ErrorRoutingModule {}

import { Injectable } from '@angular/core';
import { Permission as GlobalPermission } from '../../api/models/global-permission-dto.model';
import { UserPermissions as CoursePermission } from '../../api/models/personal-course-dto.model';
import { AuthenticationService } from './authentication.service';

export { Permission as GlobalPermission } from '../../api/models/global-permission-dto.model';
export { UserPermissions as CoursePermission } from '../../api/models/personal-course-dto.model';

@Injectable({
	providedIn: 'root',
})
export class PermissionService {
	constructor(private authenticationService: AuthenticationService) {}

	public hasGlobalPermission(globalPermission: GlobalPermission) {
		return this.authenticationService.hasSessionScope(globalPermission.valueOf());
	}

	public hasCoursePermission(courseIdentifier: string, coursePermission: CoursePermission) {
		const scopeString = coursePermission.valueOf() + '-' + courseIdentifier;
		return this.authenticationService.hasSessionScope(scopeString);
	}

	public isEnrolled(courseIdentifier: string) {
		return this.authenticationService.hasSessionScope('IS-ENROLLED-' + courseIdentifier);
	}

	public canManage(courseIdentifier: string) {
		if (this.hasGlobalPermission(GlobalPermission.MANAGE_COURSES)) return true;

		const managePermissions = [
			CoursePermission.MANAGE_LEADS,
			CoursePermission.ASSIGN_LICENCES,
			CoursePermission.MANAGE_CLASSES,
		];

		return managePermissions
			.map(p => this.hasCoursePermission(courseIdentifier, p))
			.includes(true);
	}
}

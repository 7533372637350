import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthenticatedGuard } from '../authentication/guards/is-authenticated.guard';
import { CourseEnrollmentGuard } from '../course/guards/course-enrollment.guard';
import { ChapterPlaylistView } from './views/chapter-playlist/chapter-playlist.view';

const playlistRoutes: Routes = [
	{
		path: ':courseKey/play/:blockKey',
		component: ChapterPlaylistView,
		canActivate: [IsAuthenticatedGuard, CourseEnrollmentGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(playlistRoutes)],
	exports: [RouterModule],
})
// tslint:disable-next-line: no-unnecessary-class
export class PlaylistRoutingModule {}

import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { CourseModule } from './course/course.module';
import { ErrorModule } from './error/error.module';
import { PlaylistModule } from './playlist/playlist.module';
import { UncaughtErrorHandler } from './shared/injectables/uncaught-error-handler';
import { SharedModule } from './shared/shared.module';
import { UserModule } from './user/user.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		AuthenticationModule,
		SharedModule,
		ErrorModule,
		UserModule,
		PlaylistModule,
		CourseModule,
		ApiModule,
		AppRoutingModule,
		NgbModule,
		FontAwesomeModule,
		BrowserModule,
	],
	providers: [
		{
			provide: ErrorHandler,
			useClass: UncaughtErrorHandler,
		},
	],
	bootstrap: [AppComponent],
})
// tslint:disable-next-line: no-unnecessary-class
export class AppModule {}

import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, takeUntil, tap } from 'rxjs/operators';
import { PersonalBitmasterDto } from '../../../api/models/personal-bitmaster-dto.model';
import { PersonalChapterDto } from '../../../api/models/personal-chapter-dto.model';
import { BlockIconButtonComponent } from '../../../shared/components/block-icon-button/block-icon-button.component';
import { NavigationService } from '../../../shared/injectables/navigation-service';
import { CurrentCourseService } from '../../services/current-course.service';

@Component({
	selector: 'app-course-roadmap',
	templateUrl: './course-roadmap.component.html',
	styleUrls: ['./course-roadmap.component.scss'],
})
export class CourseRoadmapComponent implements OnDestroy {
	public personalBitmaster$!: Observable<PersonalBitmasterDto>;
	public loadingPlaceholderDelayExpired$: Observable<boolean>;

	public showCommingSoonPhases$: Observable<Array<number>>;

	public courseKey!: string;
	public BlockIconButtonComponent = BlockIconButtonComponent;

	private accessibleChapterIds: Array<string> = [];
	private componentDestroyedNotifier$ = new Subject<boolean>();

	constructor(
		activatedRoute: ActivatedRoute,
		private currentCourseService: CurrentCourseService,
		public navigationService: NavigationService,
	) {
		this.currentCourseService.courseKey$
			.pipe(takeUntil(this.componentDestroyedNotifier$))
			.subscribe(key => (this.courseKey = key));
		this.personalBitmaster$ = this.currentCourseService.bitmaster$.pipe(
			tap(bitmaster => this.updateAccessibleChapterIds(bitmaster)),
		);

		this.showCommingSoonPhases$ = this.currentCourseService.bitmaster$.pipe(
			map(bitmaster => {
				if (bitmaster.id !== 'ea3704f7-0602-45b6-a390-ff044f6d31f5') return [];
				return Array.from(
					{ length: 4 - bitmaster.phases.length },
					(v, k) => k + bitmaster.phases.length + 1,
				);
			}),
		);

		this.loadingPlaceholderDelayExpired$ = timer(300).pipe(map(() => true));
	}

	public ngOnDestroy(): void {
		this.componentDestroyedNotifier$.next(true);
	}

	public isChapterAccessible(chapter: PersonalChapterDto) {
		return this.accessibleChapterIds.includes(chapter.id);
	}

	private updateAccessibleChapterIds(bitmaster: PersonalBitmasterDto) {
		this.accessibleChapterIds = [];
		for (const phase of bitmaster.phases) {
			for (const chapter of phase.chapters) {
				this.accessibleChapterIds.push(chapter.id);

				const uncompletedBlocks = chapter.sections
					.flatMap(s => s.blocks)
					.filter(b => !b.state.isCompleted);
				if (uncompletedBlocks.length > 0) return;
			}
		}
	}
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthenticatedGuard } from '../authentication/guards/is-authenticated.guard';
import { CourseLibraryComponent } from './components/course-library/course-library.component';
import { CourseManageComponent } from './components/course-manage/course-manage.component';
import { CourseRoadmapComponent } from './components/course-roadmap/course-roadmap.component';
import { CourseEnrollmentGuard } from './guards/course-enrollment.guard';
import { CourseLeadGuard } from './guards/course-lead.guard';
import { CourseDetailsView } from './views/course-details/course-details.view';
import { CourseListView } from './views/course-list/course-list.view';
import { EnrollView } from './views/enroll/enroll.view';

const courseRoutes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: CourseListView,
		canActivate: [IsAuthenticatedGuard],
	},
	{
		path: ':courseKey',
		component: CourseDetailsView,
		canActivate: [IsAuthenticatedGuard],
		children: [
			{
				path: 'roadmap',
				component: CourseRoadmapComponent,
				canActivate: [CourseEnrollmentGuard],
			},
			{
				path: 'library',
				component: CourseLibraryComponent,
				canActivate: [CourseEnrollmentGuard],
			},
			{
				path: 'manage',
				component: CourseManageComponent,
				canActivate: [CourseLeadGuard],
			},
		],
	},
	{
		path: ':courseKey/enroll',
		component: EnrollView,
	},
];

@NgModule({
	imports: [RouterModule.forChild(courseRoutes)],
	exports: [RouterModule],
})
// tslint:disable-next-line: no-unnecessary-class
export class CourseRoutingModule {}

<app-card-view [message]="message">
	<form #loginForm="ngForm" (ngSubmit)="loginFormSubmit()" class="galacta-form">
		<div class="form-group">
			<label for="usernameInput">Email</label>
			<input #usernameInput [(ngModel)]="username" name="username"
				   id="usernameInput" type="email"
				   placeholder="Mail Address" class="form-control" required/>
		</div>
		<div class="form-group">
			<label for="passwordInput">Password</label>
			<input #passwordInput [(ngModel)]="password" name="password"
				   id="passwordInput" type="password"
				   placeholder="Password" class="form-control" required/>
		</div>
		<div class="form-group mt-4 mb-0 d-flex flex-column flex-md-row">
			<a routerLink="/forgot-password" class="request-password-link">
				<small>
					Forgot your password? Don’t worry, request a reset
					link here!
				</small>
			</a>
			<button type="submit" class="btn btn-galacta-primary ml-auto"
					[disabled]="!loginForm.form.valid || submitLoadingStatus.isLoading">
				<fa-icon [icon]="faRocket"></fa-icon>
				<span class="ml-2">Login</span>
			</button>
		</div>
	</form>
	<img src="assets/images/astronaut_noun.svg" aria-hidden="true" class="astronaut-img"/>
</app-card-view>

import * as UUID from 'uuid';

export class LoadingStatus {
	private activeLoadingProcesses: Array<string> = [];

	public get isLoading() {
		return this.activeLoadingProcesses.length > 0;
	}

	public startLoadingProcess() {
		const newLoadingProcessId = UUID.v4();
		// use setTimeout to finish rendering before adding new loading-process
		// this is needed to prevent an ExpressionChangedAfterItHasBeenCheckedError in case the
		// loading-status changes during the angular rendering process
		setTimeout(() => this.activeLoadingProcesses.push(newLoadingProcessId));
		return newLoadingProcessId;
	}

	public stopLoadingProcess(loadingProcessId: string) {
		const index = this.activeLoadingProcesses.indexOf(loadingProcessId);
		if (index !== -1) this.activeLoadingProcesses.splice(index, 1);
	}
}

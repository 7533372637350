import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
	selector: 'app-card-view',
	templateUrl: './card-view.component.html',
	styleUrls: ['./card-view.component.scss'],
})
export class CardViewComponent {
	@Input()
	public message?: { icon?: IconDefinition; text: string } = undefined;
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthenticatedGuard } from '../authentication/guards/is-authenticated.guard';
import { ProfileView } from './views/profile/profile.view';
import { SettingsView } from './views/settings/settings.view';

const userRoutes: Routes = [
	{
		path: 'settings',
		pathMatch: 'full',
		component: SettingsView,
		canActivate: [IsAuthenticatedGuard],
	},
	{
		path: 'me',
		component: ProfileView,
	},
];

@NgModule({
	imports: [RouterModule.forChild(userRoutes)],
	exports: [RouterModule],
})
// tslint:disable-next-line: no-unnecessary-class
export class UserRoutingModule {}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorView } from './error/views/error/error.view';

const routes: Routes = [
	{
		path: '**',
		component: ErrorView,
		data: { code: 404, message: 'The requested page was not found.' },
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
	exports: [RouterModule],
})
// tslint:disable-next-line: no-unnecessary-class
export class AppRoutingModule {}

<app-card-view [message]="message">
	<a class="back-link" routerLink="/login">Login</a>
	<div class="card-title">Reset Password</div>
	<p class="card-text">To change your password, please enter a new one below and click the
		button.</p>
	<form #changePasswordForm="ngForm" (ngSubmit)="resetPasswordFormSubmit()" class="galacta-form">
		<div class="form-row">
			<div class="col-auto my-1 flex-grow-1">
				<label for="passwordInput">New Password</label>
				<input #passwordInput [(ngModel)]="newPassword" name="password"
					   id="passwordInput" type="password"
					   placeholder="New Password" class="form-control" required/>
			</div>
			<div
				class="col-auto my-1 align-bottom d-flex justify-content-end flex-grow-0 flex-column">
				<button type="submit" class="btn btn-galacta-primary ml-auto"
						[disabled]="!changePasswordForm.form.valid || submitLoadingStatus.isLoading">
					Change
				</button>
			</div>
		</div>
	</form>
</app-card-view>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from '../../authentication/services/permission.service';
import { AbstractCourseGuard } from './abstract-course.guard';

@Injectable({
	providedIn: 'root',
})
export class CourseLeadGuard extends AbstractCourseGuard implements CanActivate {
	constructor(private permissionService: PermissionService, private router: Router) {
		super();
	}

	public canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean> | Promise<boolean> | boolean {
		const courseKey = this.getCourseKey(next);
		if (!courseKey) {
			console.warn('[CourseLeadGuard] courseKey-param not found');
			return true;
		}

		if (this.permissionService.canManage(courseKey!)) {
			return true;
		}

		this.router.navigate(['error', '404'], { skipLocationChange: true });
		return false;
	}
}

import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { PersonalStorylineBlockContentDto } from '../../../api/models/personal-storyline-block-content-dto.model';
import { LocalisedString } from '../../../api/utils/localised-string';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { CurrentContentLocaleService } from '../../../shared/injectables/current-content-locale.service';
import {
	BlockComponent,
	BlockContainerSettings,
} from '../block-container/block-container.component';

export enum StorylineStatus {
	PLAYER_INITIALIZED = 'STORYLINE-PLAYER_INITIALIZED',
	SLIDE_LOADED = 'STORYLINE-SLIDE_LOADED',
	PLAYER_COMPLETED = 'STORYLINE-PLAYER_COMPLETED',
	NAVIGATE_NEXT_BLOCK = 'STORYLINE-NAVIGATE-NEXT_BLOCK',
	NAVIGATE_PREVIOUS_BLOCK = 'STORYLINE-NAVIGATE-PREVIOUS_BLOCK',
}

export enum StorylineAction {
	SetVar = 'SetVar',
}

@Component({
	selector: 'app-storyline-block',
	templateUrl: './storyline-block.component.html',
	styleUrls: ['./storyline-block.component.scss'],
})
export class StorylineBlockComponent implements BlockComponent, OnInit, OnChanges, OnDestroy {
	@Input()
	public blockContent!: PersonalStorylineBlockContentDto;
	@Input()
	public canNavigateToNextBlock!: boolean;
	@Output()
	public blockCompleted = new EventEmitter<void>();
	@Output()
	public blockInteraction = new EventEmitter<void>();
	@Output()
	public startNextBlockCountdown = new EventEmitter<void>();
	@Output()
	public stopNextBlockCountdown = new EventEmitter<void>();
	@Output()
	public changeContainerSettings = new EventEmitter<BlockContainerSettings>();
	@Output()
	public navigateToNextBlock = new EventEmitter<void>();
	@Output()
	public navigateToPreviousBlock = new EventEmitter<void>();

	public storylineGameUrl: Observable<SafeResourceUrl>;
	private storylineGameUrlUnlocalised = new BehaviorSubject(LocalisedString.create({}));

	@ViewChild('storylinePlayerFrame')
	private storylinePlayerFrame!: ElementRef<HTMLIFrameElement>;
	private terminateStorylinePlayerMessageSubscription?: () => void;

	constructor(
		private renderer: Renderer2,
		private sanitizer: DomSanitizer,
		private authenticationService: AuthenticationService,
		private currentContentLocaleService: CurrentContentLocaleService,
	) {
		this.storylineGameUrl = combineLatest([
			this.currentContentLocaleService.currentLocale$.pipe(distinctUntilChanged()),
			this.storylineGameUrlUnlocalised.pipe(
				distinctUntilChanged((s1, s2) => s1.equals(s2)),
				filter(unlId => unlId.values !== {}),
			),
		]).pipe(
			map(([locale, unlId]) => unlId.translation(locale)),
			filter(s => !!s),
			map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url)),
			shareReplay(),
		);
	}

	public ngOnInit() {
		this.terminateStorylinePlayerMessageSubscription = this.renderer.listen(
			'window',
			'message',
			event => this.onStorylinePlayerMessage(event),
		);

		this.changeContainerSettings.emit({
			showPreviousBlockControl: true,
			showNextBlockControl: true,
			showFullscreenControl: true,
		});
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.blockContent) {
			const currentUrl = changes.blockContent.currentValue.storylineGameUrl;
			this.storylineGameUrlUnlocalised.next(LocalisedString.fromDto(currentUrl));
		}
	}

	public ngOnDestroy() {
		if (this.terminateStorylinePlayerMessageSubscription) {
			this.terminateStorylinePlayerMessageSubscription();
		}
	}

	private onStorylinePlayerMessage(messageEvent: MessageEvent) {
		if (
			!messageEvent.origin.match(
				/^https:\/\/galacta-storyline-games.ams3.digitaloceanspaces.com/,
			) &&
			!messageEvent.origin.match(/^https:\/\/games.assets.galacta.com/) &&
			!messageEvent.origin.match(/^https:\/\/app(\..+)?.galacta.com/)
		) {
			console.error('message from untrusted origin', messageEvent.origin);
			return;
		}

		switch (messageEvent.data) {
			case StorylineStatus.PLAYER_INITIALIZED:
				this.onStorylinePlayerInitialized();
				break;
			case StorylineStatus.SLIDE_LOADED:
				this.onStorylineSlideLoaded();
				break;
			case StorylineStatus.PLAYER_COMPLETED:
				this.onStorylinePlayerCompleted();
				break;
			case StorylineStatus.NAVIGATE_NEXT_BLOCK:
				this.navigateToNextBlock.emit();
				break;
			case StorylineStatus.NAVIGATE_PREVIOUS_BLOCK:
				this.navigateToPreviousBlock.emit();
				break;
		}
	}

	private onStorylinePlayerInitialized() {
		this.storylinePlayerFrame.nativeElement.contentWindow!.postMessage(
			{
				action: StorylineAction.SetVar,
				target: 'user_firstname',
				value: this.authenticationService.sessionInfo!.firstName,
			},
			'*',
		);
		this.storylinePlayerFrame.nativeElement.contentWindow!.postMessage(
			{
				action: StorylineAction.SetVar,
				target: 'user_lastname',
				value: this.authenticationService.sessionInfo!.lastName,
			},
			'*',
		);
		this.storylinePlayerFrame.nativeElement.contentWindow!.postMessage(
			{
				action: StorylineAction.SetVar,
				target: 'user_email_address',
				value: this.authenticationService.sessionInfo!.emailAddress,
			},
			'*',
		);
		this.storylinePlayerFrame.nativeElement.contentWindow!.postMessage(
			{
				action: StorylineAction.SetVar,
				target: 'is_next_block_enabled',
				value: this.canNavigateToNextBlock,
			},
			'*',
		);
	}

	private onStorylineSlideLoaded() {
		this.blockInteraction.emit();
	}

	private onStorylinePlayerCompleted() {
		this.blockCompleted.emit();
	}
}

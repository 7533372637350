import { LocalisedStringDto } from '../models/localised-string-dto.model';

export class LocalisedString {
	public values: { [key: string]: string } = {};

	public get availableLocales() {
		return Object.keys(this.values).sort();
	}

	public toDto() {
		return Object.keys(this.values)
			.map(k => [k, this.values[k]])
			.map(([locale, value]) => {
				return {
					locale,
					localisedValue: value,
				} as LocalisedStringDto;
			});
	}

	public translation(wantedLocale: string, ...fallbackLocales: Array<string>): string {
		const availableLocales = Object.keys(this.values);
		if (availableLocales.includes(wantedLocale)) {
			return this.values[wantedLocale];
		}

		const [wLocale] = wantedLocale.split('_');
		const found = availableLocales.find(l => l.startsWith(wLocale));
		if (found) {
			return this.values[found];
		}

		// if there are no fallback locales, just return the first one
		if (fallbackLocales.length === 0) {
			return this.values[availableLocales[0]];
		}
		// if no locale was found but there are still fallback locales, try one of those.
		else {
			return this.translation(fallbackLocales.shift()!, ...fallbackLocales);
		}
	}

	public equals(other: LocalisedString | LocalisedString.Dto): boolean {
		if (other === undefined || other === null) {
			return false;
		}

		if (other instanceof LocalisedString) {
			const thisLocales = this.availableLocales;
			const otherLocales = other.availableLocales;
			// check if the number of locales is different
			if (thisLocales.length !== otherLocales.length) return false;
			// if they are the same number, compare the keys at the same position (we assume
			// that `availableLocales` is a sorted array) and check their actual values
			for (let i = 0; i < thisLocales.length; i++) {
				if (thisLocales[i] !== otherLocales[i]) return false;
				if (this.values[thisLocales[i]] !== other.values[otherLocales[i]]) return false;
			}
			return true;
		} else {
			return this.equals(LocalisedString.fromDto(other));
		}
	}
}

// tslint:disable-next-line:no-namespace
export namespace LocalisedString {
	export type Dto = Array<LocalisedStringDto>;

	export const create = (values: { [key: string]: string }) => {
		const localisedString = new LocalisedString();
		localisedString.values = values;
		return localisedString;
	};

	export const fromDto = (dto: LocalisedString.Dto) => {
		const localisedString = new LocalisedString();
		dto.forEach(so => {
			localisedString.values[so.locale] = so.localisedValue;
		});
		return localisedString;
	};
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
	providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {
	constructor(private router: Router, private authenticationService: AuthenticationService) {}

	public canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean> | Promise<boolean> | boolean {
		if (this.authenticationService.isAuthenticated) return true;

		this.router.navigate(['/login'], { queryParams: { sourceUrl: state.url } });
		return false;
	}
}

import { BaseApiService } from './base-api.service';
import { GlobalPermissionCreationDto } from './../models/global-permission-creation-dto.model';
import { GlobalPermissionDto } from './../models/global-permission-dto.model';
import { ListMetadataDto } from './../models/list-metadata-dto.model';
import { PaginatedListMetadataDto } from './../models/paginated-list-metadata-dto.model';
import { UserChangePasswordDto } from './../models/user-change-password-dto.model';
import { UserCreationDto } from './../models/user-creation-dto.model';
import { UserDto } from './../models/user-dto.model';
import { UserRegisterDto } from './../models/user-register-dto.model';
import { UserUpdateDto } from './../models/user-update-dto.model';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UsersApiService extends BaseApiService {
	private constructor(private http: HttpClient) {
		super();
	}

	/** Get all existing users */
	public getUsers$(params: { query?: string, pageNumber?: number, pageSize?: number } = {}): Observable<{ items: Array<UserDto>, meta: PaginatedListMetadataDto }> {
		const path = `users`;

		const options: any = { headers: this.defaultHeaders };
		options.params = new HttpParams();

		Object.entries(params)
			.filter(([key, value]) => !!value)
			.forEach(([key, value]) => (options.params = options.params.set(key, value)));

		return this.http.request<{ items: Array<UserDto>, meta: PaginatedListMetadataDto }>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getUsers(params: { query?: string, pageNumber?: number, pageSize?: number } = {}): Promise<{ items: Array<UserDto>, meta: PaginatedListMetadataDto }> {
		return this.getUsers$(params).toPromise();
	}

	/** Create a new user */
	public createUser$(body: UserCreationDto): Observable<UserDto> {
		const path = `users`;

		const options: any = { headers: this.defaultHeaders };
		options.body = body;

		return this.http.request<UserDto>(
			'POST',
			this.basePath + path,
			options as object,
		);
	}

	public createUser(body: UserCreationDto): Promise<UserDto> {
		return this.createUser$(body).toPromise();
	}

	/** Get the user that is logged in */
	public getLoggedInUser$(): Observable<UserDto> {
		const path = `users/me`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<UserDto>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getLoggedInUser(): Promise<UserDto> {
		return this.getLoggedInUser$().toPromise();
	}

	/** Get a specific user */
	public getUserById$(id: string): Observable<UserDto> {
		const path = `users/${id}`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<UserDto>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getUserById(id: string): Promise<UserDto> {
		return this.getUserById$(id).toPromise();
	}

	/** Delete a specific user */
	public deleteUserWithId$(id: string): Observable<void> {
		const path = `users/${id}`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<void>(
			'DELETE',
			this.basePath + path,
			options as object,
		);
	}

	public deleteUserWithId(id: string): Promise<void> {
		return this.deleteUserWithId$(id).toPromise();
	}

	/** Update a specific user */
	public updateUser$(id: string, body: UserUpdateDto): Observable<UserDto> {
		const path = `users/${id}`;

		const options: any = { headers: this.defaultHeaders };
		options.body = body;

		return this.http.request<UserDto>(
			'PATCH',
			this.basePath + path,
			options as object,
		);
	}

	public updateUser(id: string, body: UserUpdateDto): Promise<UserDto> {
		return this.updateUser$(id, body).toPromise();
	}

	/** Get all global permissions of a specific user */
	public getUserPermissions$(id: string): Observable<{ items: Array<GlobalPermissionDto>, meta: ListMetadataDto }> {
		const path = `users/${id}/permissions`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<{ items: Array<GlobalPermissionDto>, meta: ListMetadataDto }>(
			'GET',
			this.basePath + path,
			options as object,
		);
	}

	public getUserPermissions(id: string): Promise<{ items: Array<GlobalPermissionDto>, meta: ListMetadataDto }> {
		return this.getUserPermissions$(id).toPromise();
	}

	/** Add a global permissions to a specific user */
	public addUserPermission$(id: string, body: GlobalPermissionCreationDto): Observable<GlobalPermissionDto> {
		const path = `users/${id}/permissions`;

		const options: any = { headers: this.defaultHeaders };
		options.body = body;

		return this.http.request<GlobalPermissionDto>(
			'POST',
			this.basePath + path,
			options as object,
		);
	}

	public addUserPermission(id: string, body: GlobalPermissionCreationDto): Promise<GlobalPermissionDto> {
		return this.addUserPermission$(id, body).toPromise();
	}

	/** Update the user's password */
	public changePassword$(body: UserChangePasswordDto): Observable<void> {
		const path = `users/change-password`;

		const options: any = { headers: this.defaultHeaders };
		options.body = body;

		return this.http.request<void>(
			'POST',
			this.basePath + path,
			options as object,
		);
	}

	public changePassword(body: UserChangePasswordDto): Promise<void> {
		return this.changePassword$(body).toPromise();
	}

	/** Set personal data of INVITED user and change status to ACTIVE. */
	public registerUser$(body: UserRegisterDto): Observable<UserDto> {
		const path = `users/register`;

		const options: any = { headers: this.defaultHeaders };
		options.body = body;

		return this.http.request<UserDto>(
			'POST',
			this.basePath + path,
			options as object,
		);
	}

	public registerUser(body: UserRegisterDto): Promise<UserDto> {
		return this.registerUser$(body).toPromise();
	}

	/** Update the user's password */
	public requestPasswordReset$(email: string): Observable<void> {
		const path = `users/${email}/reset-password`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<void>(
			'POST',
			this.basePath + path,
			options as object,
		);
	}

	public requestPasswordReset(email: string): Promise<void> {
		return this.requestPasswordReset$(email).toPromise();
	}

	/** Remove a global permissions from a specific user */
	public removeUserPermission$(id: string, permissionId: string): Observable<void> {
		const path = `users/${id}/permissions/${permissionId}`;

		const options: any = { headers: this.defaultHeaders };

		return this.http.request<void>(
			'DELETE',
			this.basePath + path,
			options as object,
		);
	}

	public removeUserPermission(id: string, permissionId: string): Promise<void> {
		return this.removeUserPermission$(id, permissionId).toPromise();
	}

}

<div class="chapter-tile galacta-card row">
	<div class="col-12 col-lg-3 col-md-12 chapter-info">
		<div class="chapter-label">Chapter {{ chapter?.ordinal }}</div>
		<div class="chapter-name">{{ chapter?.name | localise }}</div>
	</div>
	<div class="col-12 col-lg-3 col-md-4" [class.p-0]="!classroomBlocks.length">
		<div class="block column-heading">
			<div class="classroom-background"></div>
			<span class="flex-grow-1 mx-1 classroom-text">Classroom</span>
		</div>
		<a *ngFor="let block of classroomBlocks" class="block"
		   [routerLink]="['..', 'play', block.info.blockAssignmentKey]">
			<app-block-icon-button [blockInfo]="block.info"></app-block-icon-button>
			<span class="flex-grow-1 mx-1">{{ block.info.name | localise }}</span>
			<app-icon-button *ngIf="block.state.isFavourite" [icon]="fasStar"></app-icon-button>
		</a>
	</div>
	<div class="col-12 col-lg-3 col-md-4" [class.p-0]="!workshopBlocks.length">
		<div class="block column-heading">
			<div class="workshop-background"></div>
			<span class="flex-grow-1 mx-1 workshop-text">Workshop</span>
		</div>
		<a *ngFor="let block of workshopBlocks" class="block"
		   [routerLink]="['..', 'play', block.info.blockAssignmentKey]">
			<app-block-icon-button [blockInfo]="block.info"></app-block-icon-button>
			<span class="flex-grow-1 mx-1">{{ block.info.name | localise }}</span>
			<app-icon-button *ngIf="block.state.isFavourite" [icon]="fasStar"></app-icon-button>
		</a>
	</div>
	<div class="col-12 col-lg-3 col-md-4" [class.p-0]="!projectBlocks.length">
		<div class="block column-heading">
			<div class="project-background"></div>
			<span class="flex-grow-1 mx-1 project-text">Project</span>
		</div>
		<a *ngFor="let block of projectBlocks" class="block"
		   [routerLink]="['..', 'play', block.info.blockAssignmentKey]">
			<app-block-icon-button [blockInfo]="block.info"></app-block-icon-button>
			<span class="flex-grow-1 mx-1">{{ block.info.name | localise }}</span>
			<app-icon-button *ngIf="block.state.isFavourite" [icon]="fasStar"></app-icon-button>
		</a>
	</div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorRoutingModule } from './error-routing.module';
import { ErrorView } from './views/error/error.view';

@NgModule({
	declarations: [ErrorView],
	imports: [CommonModule, ErrorRoutingModule],
})
// tslint:disable-next-line: no-unnecessary-class
export class ErrorModule {}

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { SessionTokenInterceptor } from './services/session-token.interceptor';
import { ForgotPasswordView } from './views/forgot-password/forgot-password.view';
import { LoginView } from './views/login/login.view';
import { LogoutView } from './views/logout/logout.view';
import { ResetPasswordView } from './views/reset-password/reset-password.view';

@NgModule({
	declarations: [LoginView, LogoutView, ForgotPasswordView, ResetPasswordView],
	imports: [
		NgbModule,
		FontAwesomeModule,
		FormsModule,
		CommonModule,
		AuthenticationRoutingModule,
		SharedModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SessionTokenInterceptor,
			multi: true,
		},
	],
})
// tslint:disable-next-line: no-unnecessary-class
export class AuthenticationModule {}

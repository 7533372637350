import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { PersonalCourseDto } from '../../../api/models/personal-course-dto.model';
import { CoursesApiService } from '../../../api/services/courses.service';
import { NavigationService } from '../../../shared/injectables/navigation-service';

@Component({
	selector: 'app-course-list',
	templateUrl: './course-list.view.html',
	styleUrls: ['./course-list.view.scss'],
})
export class CourseListView implements OnInit {
	public courseSummaries$!: Observable<Array<PersonalCourseDto>>;

	constructor(
		private courseService: CoursesApiService,
		public navigationService: NavigationService,
	) {}

	public ngOnInit(): void {
		this.courseSummaries$ = this.courseService.getUserCourses$().pipe(map(cs => cs.items));
	}
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faUserAstronaut } from '@fortawesome/pro-solid-svg-icons/faUserAstronaut';
import { AuthenticationService } from '../../../authentication/services/authentication.service';

@Component({
	selector: 'app-logged-in-user-tile',
	templateUrl: './logged-in-user-tile.component.html',
	styleUrls: ['./logged-in-user-tile.component.scss'],
})
export class LoggedInUserTileComponent implements OnInit {
	public displayedUser!: { name: string; emailAddress: string };

	public faUserAstronaut = faUserAstronaut;

	public get returnUrl(): string {
		return this.router.url;
	}

	constructor(private router: Router, private authenticationService: AuthenticationService) {}

	public ngOnInit() {
		const sessionInfo = this.authenticationService.sessionInfo!;
		this.displayedUser = {
			name: `${sessionInfo.firstName} ${sessionInfo.lastName}`,
			emailAddress: sessionInfo.emailAddress,
		};
	}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { UserModule } from '../user/user.module';
import { BlockContainerComponent } from './components/block-container/block-container.component';
import { StorylineBlockComponent } from './components/storyline-block/storyline-block.component';
import { VimeoBlockComponent } from './components/vimeo-block/vimeo-block.component';
import { PlaylistRoutingModule } from './playlist-routing.module';
import { ChapterPlaylistView } from './views/chapter-playlist/chapter-playlist.view';
import { UnityBlockComponent } from './components/unity-block/unity-block.component';

@NgModule({
	declarations: [
		ChapterPlaylistView,
		BlockContainerComponent,
		VimeoBlockComponent,
		StorylineBlockComponent,
		UnityBlockComponent,
	],
	imports: [FontAwesomeModule, CommonModule, SharedModule, UserModule, PlaylistRoutingModule],
	exports: [BlockContainerComponent],
})
// tslint:disable-next-line: no-unnecessary-class
export class PlaylistModule {}

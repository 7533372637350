import { LocalisedStringDto } from './../models/localised-string-dto.model';
import { PersonalBlockDetailDto } from './../models/personal-block-detail-dto.model';

export enum UserPermissions {
	MANAGE_LEADS = 'MANAGE_LEADS',
	MANAGE_CLASSES = 'MANAGE_CLASSES',
	ASSIGN_LICENCES = 'ASSIGN_LICENCES',
	ASSIGN_REVIEWERS = 'ASSIGN_REVIEWERS',
	REVIEW_ASSIGNMENTS = 'REVIEW_ASSIGNMENTS',
}

export interface PersonalCourseDto {
	courseId: string;
	courseKey: string;
	courseName: LocalisedStringDto[];
	isPreviewCourse: boolean;
	bitmasterId: string;
	bitmasterName: LocalisedStringDto[];
	imageUrl: string;
	brandingImageUrl: string;
	teaser: LocalisedStringDto[];
	description: LocalisedStringDto[];
	availableLocales: string[];
	userPreferredLocale?: string;
	userPermissions: UserPermissions[];
	userIsEnrolled: boolean;
	enrolmentIsExpired: boolean;
	lastUserInteraction?: string;
	firstUncompletedBlock?: PersonalBlockDetailDto;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faTv } from '@fortawesome/pro-regular-svg-icons/faTv';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { Observable, timer } from 'rxjs';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { Subject } from 'rxjs/internal/Subject';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { PersonalCourseDto } from '../../../api/models/personal-course-dto.model';
import { UserSettingsApiService } from '../../../api/services/user-settings.service';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { CurrentContentLocaleService } from '../../../shared/injectables/current-content-locale.service';
import { NavigationService } from '../../../shared/injectables/navigation-service';
import { CurrentCourseService } from '../../services/current-course.service';

@Component({
	selector: 'app-course-details',
	templateUrl: './course-details.view.html',
	styleUrls: ['./course-details.view.scss'],
	providers: [CurrentCourseService, CurrentContentLocaleService],
})
export class CourseDetailsView implements OnInit, OnDestroy {
	public course$: Observable<PersonalCourseDto>;
	public isEnrolled$: Observable<boolean>;
	public canManage$: Observable<boolean>;
	public loadingPlaceholderDelayExpired$: Observable<boolean>;

	public faChevronLeft = faChevronLeft;
	public faTv = faTv;

	private componentDestroyedNotifier$ = new Subject<boolean>();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private currentCourseService: CurrentCourseService,
		private currentContentLocaleService: CurrentContentLocaleService,
		private authenticationService: AuthenticationService,
		private userSettingsApiService: UserSettingsApiService,
		public navigationService: NavigationService,
	) {
		this.currentCourseService.init(
			this.activatedRoute.params.pipe(
				map(params => params.courseKey as string),
				shareReplay(),
			),
		);

		this.course$ = this.currentCourseService.course$;
		this.isEnrolled$ = this.currentCourseService.isEnrolled$;
		this.canManage$ = this.currentCourseService.canManage$;

		this.course$.pipe(takeUntil(this.componentDestroyedNotifier$)).subscribe(course => {
			this.currentContentLocaleService.setCurrentLocale(
				course.userPreferredLocale || course.availableLocales[0] || 'en',
			);
			this.currentContentLocaleService.setAvailableLocales(course.availableLocales);
		});

		this.currentContentLocaleService.currentLocale$
			.pipe(takeUntil(this.componentDestroyedNotifier$), withLatestFrom(this.course$))
			.subscribe(([locale, course]) => {
				this.userSettingsApiService.setPreferredLocaleForCourse(
					course.courseKey,
					this.authenticationService.sessionInfo!.userId,
					{ preferredLocale: locale },
				);
			});

		this.loadingPlaceholderDelayExpired$ = timer(300).pipe(map(() => true));
	}

	public async ngOnInit() {
		// If no child route was loaded, redirect to one or show error
		combineLatest([this.isEnrolled$, this.canManage$])
			.pipe(
				takeUntil(this.componentDestroyedNotifier$),
				filter(() => this.activatedRoute.children.length === 0),
			)
			.subscribe(([isEnrolled, canManage]) => {
				if (isEnrolled) {
					this.router.navigate(['roadmap'], {
						relativeTo: this.activatedRoute,
					});
				} else if (canManage) {
					this.router.navigate(['manage'], { relativeTo: this.activatedRoute });
				} else {
					this.router.navigate(['error', '401'], { skipLocationChange: true });
				}
			});
	}

	public ngOnDestroy() {
		this.componentDestroyedNotifier$.next(true);
	}
}
